const sectionFooterStyle = theme => ({
  partners: {
    textAlign: 'left',
    marginTop: '20px'
  },
  partnersTitle: {
    fontSize: "32px",
    fontFamily: "Arial",
    fontWeight: "bold",
    lineHeight: "37px",
    color: "#2D2E35",
  },
  partnersList:{
    display: 'flex'
  },
  partnersImg:{
    width: "100%",
    marginTop:"26px",
    height: "68px"
  }
})


export default sectionFooterStyle;