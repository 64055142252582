/* eslint-disable */
import React, { useState, useEffect } from 'react';
import BigNumber from "bignumber.js";
import { byDecimals, calculateReallyNum, forMatVal, toNonExponential } from 'features/helpers/bignumber';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { primaryColor } from "assets/jss/material-kit-pro-react.js";
// core components
import Button from "components/CustomButtons/Button.js";
import Avatar from '@material-ui/core/Avatar';
import { useSnackbar } from 'notistack';
//  hooks
import { useConnectWallet } from '../../home/redux/hooks';
import {
  useFetchPoolsInfo, useFetchBalance, useFetchRewardsAvailable, useFetchCurrentlyStaked, useFetchApproval,
  useFetchStake, useFetchWithdraw, useFetchClaim, useFetchExit, useFetchContractApy
} from '../redux/hooks';
import CustomSlider from 'components/CustomSlider/CustomSlider';

import sectionPoolsStyle from "../jss/sections/sectionPoolsStyle";
import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';

const useStyles = makeStyles(sectionPoolsStyle);

export default function StakePools() {
  const { web3, address, networkId } = useConnectWallet();
  const { pools, fetchPoolsInfo } = useFetchPoolsInfo();
  const { tokens, fetchBalances } = useFetchBalance();
  const { rewardsAvailable, fetchRewardsAvailable } = useFetchRewardsAvailable();
  const { currentlyStaked, fetchCurrentlyStaked } = useFetchCurrentlyStaked();
  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const { fetchStake, fetchStakePending } = useFetchStake();
  const { fetchWithdraw, fetchWithdrawPending } = useFetchWithdraw();
  const { fetchClaim, fetchClaimPending } = useFetchClaim();
  const { fetchExit, fetchExitPending } = useFetchExit();

  const { contractApy, fetchContractApy } = useFetchContractApy();
  const classes = useStyles();

  const [openedCardList, setOpenCardList] = useState([]);
  const [stakeBalance, setStakeBalance] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  // onChange={changeDetailInputValue.bind(this, 'stakeBalance', index, 0, pool.tokenDecimals)}
  const changeDetailInputValue = (type, index, total, tokenDecimals, dec, event) => {
    const num = forMat(total, dec)
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    let sliderNum = 0;
    let inputVal = Number(forMatVal(value.toString(), dec).replace(',', ''));
    console.log(inputVal);
    if (value) {
      sliderNum = byDecimals(inputVal / num, 0).toFormat(2) * 100;
    }
    switch (type) {
      case 'stakeBalance':
        setStakeBalance({
          ...stakeBalance,
          [index]: inputFinalVal(value, num, dec, tokenDecimals),
          [`slider-${index}`]: sliderNum,
        });
        break;
      case 'withdrawAmount':
        setWithdrawAmount({
          ...withdrawAmount,
          [index]: inputFinalVal(value, num, dec, tokenDecimals),
          [`slider-${index}`]: sliderNum,
        });
        break;
      default:
        break;
    }
  }

  const handleDepositedBalance = (index, total, dec, event, sliderNum) => {
    const num = forMat(total, dec)
    setStakeBalance({
      ...stakeBalance,
      [index]: sliderNum == 0 ? 0 : calculateReallyNum(num, sliderNum, dec),
      [`slider-${index}`]: sliderNum == 0 ? 0 : sliderNum,
    });
  }

  const handleWithdrawAmount = (index, total, dec, event, sliderNum) => {
    const num = forMat(total, dec)
    setWithdrawAmount({
      ...withdrawAmount,
      [index]: sliderNum == 0 ? 0 : calculateReallyNum(num, sliderNum, dec),
      [`slider-${index}`]: sliderNum == 0 ? 0 : sliderNum,
    });
  };


  const onStake = (pool, index, event) => {
    event.stopPropagation();
    let amountValue = stakeBalance[index] ? stakeBalance[index].toString().replace(',', '') : stakeBalance[index];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    const amount = new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10)
    if (Number(amount) > 0) {
      if (Boolean(pool.allowance === 0)) {
        fetchApproval({ address, web3, pool, index }).then(() => {
          fetchStake({ address, web3, pool, amount, index })
        })
      } else {
        fetchStake({ address, web3, pool, amount, index })
      }
    }
  }
  const onUnstake = (pool, index, stakeSingle, event) => {
    event.stopPropagation();
    if (Boolean(forMat(stakeSingle, pool.valueDecimals)) && Boolean(withdrawAmount[index])) {
      let amountValue = withdrawAmount[index] ? withdrawAmount[index].toString().replace(',', '') : withdrawAmount[index];
      if (amountValue == undefined) {
        amountValue = '0';
      }
      const amount = new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10)
      fetchWithdraw({ address, web3, pool, amount, index })
    }

  }
  const onClaimRewards = (pool, index, rewardsSingle, event) => {
    event.stopPropagation();
    if (Boolean(forMat(rewardsSingle, pool.valueDecimals))) {
      fetchClaim({ address, web3, pool, index })
    }
  }
  const onExit = (pool, index, stakeSingle, event) => {
    event.stopPropagation();
    if (Boolean(forMat(stakeSingle, pool.valueDecimals))) {
      fetchExit({ address, web3, pool, index })
    }
  }


  const openCard = id => {
    return setOpenCardList(
      openedCardList => {
        if (openedCardList.includes(id)) {
          return openedCardList.filter(item => item !== id)
        } else {
          return [...openedCardList, id]
        }
      }
    )
  }

  useEffect(() => {
    if (address && web3) {
      fetchBalances({ address, web3, tokens });
      fetchRewardsAvailable({ address, web3, rewardsAvailable });
      fetchCurrentlyStaked({ address, web3, currentlyStaked });
      fetchPoolsInfo({ address, web3, pools });
      const id = setInterval(() => {
        fetchBalances({ address, web3, tokens });
        fetchRewardsAvailable({ address, web3, rewardsAvailable });
        fetchCurrentlyStaked({ address, web3, currentlyStaked });

        fetchPoolsInfo({ address, web3, pools });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, web3, fetchBalances, fetchRewardsAvailable, fetchCurrentlyStaked, fetchPoolsInfo]);

  const forMat = (number, dec = 4) => {
    return toNonExponential(new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10).exponentiatedBy(dec)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10).exponentiatedBy(dec)
    ).toNumber())
  }

  useEffect(() => {
    fetchContractApy();
    const id = setInterval(() => {
      fetchContractApy();
    }, 10000);
    return () => clearInterval(id);
  }, [fetchContractApy]);
  return (
    <Grid container style={{ paddingTop: '4px' }}>

      {pools.map((pool, index) => {
        let balanceSingle = byDecimals(tokens[pool.tokenAddress].tokenBalance, pool.tokenDecimals);
        let rewardsSingle = byDecimals(rewardsAvailable[pool.tokenAddress].tokenRewards, pool.earnedTokenDecimals);
        let stakeSingle = byDecimals(currentlyStaked[pool.tokenAddress].tokenStaked, pool.tokenDecimals);
        let depositedApy = `${contractApy[pool.stakeApy] || 0}%`;
        return (
          <Grid item xs={12} container key={index} style={{ marginBottom: "24px", position: "relative" }} spacing={0}>
            {
              pool.isVersion === '2' ? (
                <div className={classes.version} style={{ borderTop: "36px solid #FEC06C" }}><div className={classes.versionText}>{`V${pool.isVersion}`}</div></div>
              ) : pool.isVersion === '1' ? (
                <div className={classes.version} style={{ borderTop: "36px solid #7A7EFF" }}><div className={classes.versionText}>{`V${pool.isVersion}`}</div></div>
              ) : (null)
            }
            <div style={{ width: "100%" }}>
              <Accordion
                expanded={Boolean(openedCardList.includes(index))}
                className={classes.accordion}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  className={classes.details}
                  style={{ justifyContent: "space-between" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    openCard(index)
                  }}
                >
                  <Grid container alignItems="center" justify="space-around" spacing={2} style={{ paddingTop: "6px", paddingBottom: "6px" }}>
                    <Grid item md={3} xs={4}>
                      <Grid container justify="space-between" spacing={2}>

                        <Hidden mdDown>
                          <Grid item >
                            <Avatar
                              alt={pool.token}
                              src={require(`../../../images/${pool.token}-logo.png`)}
                            />
                          </Grid>
                        </Hidden>
                        <Grid item style={{ flex: 1, width: '100px' }}>
                          <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>
                            {pool.token}
                            <Hidden smUp>
                              <i
                                style={{ color: primaryColor[0], marginLeft: '4px', visibility: Boolean(pool.tokenDescriptionUrl) ? "visible" : "hidden" }}
                                className={"yfiiicon yfii-help-circle"}
                                onClick={
                                  event => {
                                    event.stopPropagation();
                                    window.open(pool.tokenDescriptionUrl)
                                  }
                                }
                              />
                            </Hidden>
                          </Typography>

                          <Typography className={classes.iconContainerSubTitle} variant="body2" noWrap>{pool.token}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={8} xs={8} >
                      <Grid item container spacing={2} justify="space-between">
                        <Grid item xs={6} md={3} container alignItems="center">
                          <Grid item>
                            <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>{depositedApy}</Typography>
                            <Typography className={classes.iconContainerSubTitle} variant="body2">APY</Typography></Grid>
                        </Grid>
                        <Grid item xs={6} md={3} container justify='center' alignItems="center">
                          <Grid item style={{ width: "100%" }}>
                            <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>
                              {forMat(rewardsSingle, pool.valueDecimals)} {pool.earnedToken}
                            </Typography>
                            <Typography className={classes.iconContainerSubTitle} variant="body2">Earned</Typography></Grid>
                        </Grid>
                        <Hidden mdDown>
                          <Grid item xs={6} md={3} container justify='center' alignItems="center">
                            <Grid item style={{ width: "100%" }}>
                              <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>
                                {forMat(balanceSingle, pool.valueDecimals)} {pool.token}
                              </Typography>
                              <Typography className={classes.iconContainerSubTitle} variant="body2">Balance</Typography>
                            </Grid>
                          </Grid>
                        </Hidden>
                        <Hidden mdDown>
                          <Grid item xs={6} md={3} container justify='center' alignItems="center">
                            <Grid item style={{ width: "100%" }}>
                              <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>
                                {forMat(stakeSingle, pool.valueDecimals)} {pool.token}
                              </Typography>
                              <Typography className={classes.iconContainerSubTitle} variant="body2">Staked</Typography>
                            </Grid>
                          </Grid>
                        </Hidden>
                      </Grid>
                    </Grid>

                    <Grid item md={1}>
                      <Grid item container justify="flex-end" alignItems="center" spacing={2}>
                        <Grid item>
                          <div className={classes.detailsBtnWrap} onClick={(event) => { event.stopPropagation(); openCard(index); }}>
                            {
                              openedCardList.includes(index) ?
                                <div className={classes.detailsBtn}>
                                  <span>Detail</span> <img src={require(`../../../images/less_icon.png`)} className={classes.iconImg} />
                                </div>
                                :
                                <div className={classes.detailsBtn}>
                                  <span>Detail</span> <img src={require(`../../../images/more_icon.png`)} className={classes.iconImg} />
                                </div>
                            }
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <div style={{ justifyContent: "space-between" }}>
                  <Grid xs={12} item container style={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
                    <Grid item xs={12} sm={6} className={classes.sliderDetailContainer}>
                      <div className={classes.showDetailRight}>
                        balance:{forMat(balanceSingle, pool.valueDecimals)} {pool.token}
                      </div>
                      <FormControl fullWidth variant="outlined">
                        <div style={{ background: "#F8F9FA", borderRadius: "12px", color: '#2D2E35' }}>
                          <CustomOutlinedInput
                            value={stakeBalance[index] != undefined ? stakeBalance[index] : '0'}
                            onChange={changeDetailInputValue.bind(this, 'stakeBalance', index, balanceSingle, pool.tokenDecimals, pool.valueDecimals)}
                          />
                        </div>
                      </FormControl>
                      <div className={classes.sliderWrap}>
                        <CustomSlider
                          classes={{
                            root: classes.depositedBalanceSliderRoot,
                            markLabel: classes.depositedBalanceSliderMarkLabel,
                          }}
                          aria-labelledby="continuous-slider"
                          value={stakeBalance['slider-' + index] ? stakeBalance['slider-' + index] : 0}
                          onChange={handleDepositedBalance.bind(this, index, balanceSingle.toNumber(), pool.valueDecimals)}
                        />
                      </div>
                      <div className={classes.showDetailButtonCon}>
                        <Button onFocus={(event) => event.stopPropagation()} onClick={onStake.bind(this, pool, index)}>Stake </Button>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.sliderDetailContainer}>
                      <div className={classes.showDetailRight}>
                        Staked: {forMat(stakeSingle, pool.valueDecimals)} {pool.token}
                      </div>
                      <FormControl fullWidth variant="outlined">
                        <div style={{ background: "#F8F9FA", borderRadius: "12px", color: '#2D2E35' }}>
                          <CustomOutlinedInput
                            value={withdrawAmount[index] != undefined ? withdrawAmount[index] : '0'}
                            onChange={changeDetailInputValue.bind(this, 'withdrawAmount', index, stakeSingle, pool.tokenDecimals, pool.valueDecimals)}
                          />
                        </div>
                      </FormControl>
                      <div className={classes.sliderWrap}>
                        <CustomSlider
                          classes={{
                            root: classes.drawSliderRoot,
                            markLabel: classes.drawSliderMarkLabel,
                          }}
                          aria-labelledby="continuous-slider"
                          value={withdrawAmount['slider-' + index] ? withdrawAmount['slider-' + index] : 0}
                          onChange={handleWithdrawAmount.bind(this, index, stakeSingle.toNumber(), pool.valueDecimals)}
                        />
                      </div>
                      <div className={classes.showDetailButtonCon}>
                        <Button onFocus={(event) => event.stopPropagation()} onClick={onUnstake.bind(this, pool, index, stakeSingle.toNumber())}>Unstake</Button>
                      </div>
                    </Grid>

                  </Grid>

                  <Grid xs={12} item container style={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
                    <div className={classes.showDetailOrtherButtonCon}>
                      <Button
                        onFocus={(event) => event.stopPropagation()}
                        className={classes.detailsOrtherBtn}
                        onClick={onClaimRewards.bind(this, pool, index, rewardsSingle)}>
                        Claim Rewards
                      </Button>
                    </div>
                  </Grid>
                  <Grid xs={12} item container style={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
                    <div className={classes.showDetailOrtherButtonCon}>
                      {/* disabled={
                          !Boolean(stakeBalance[index]) || (new BigNumber(stakeBalance[index]).toNumber() > 0)
                        } */}
                      <Button
                        onFocus={(event) => event.stopPropagation()}
                        className={classes.detailsOrtherBtn}
                        onClick={onExit.bind(this, pool, index, stakeSingle)}>
                        Exit:Claim And Unstake
                      </Button>
                    </div>
                  </Grid>
                </div>
              </Accordion>
            </div>
          </Grid>
        )
      })}

    </Grid>
  )
}
