const sectionClaimStyle = theme => ({
  claimWrap: {
    color: "#FFFFFF",
  },
  claimTitle: {
    fontSize: "42px",
    fontWeight: "bold",
    lineHeight: "48px",
    textAlign: 'center',
    padding: "40px 0"
  },
  claimText: {
    fontSize: "16px",
    lineHeight: "18px",
  },
  claimImgWrap: {
    textAlign: 'center',
    paddingTop: "50px",
  },
  claimImg: {
    width: '350px'
  },
  userClaim: {
    textAlign: 'center',
  },
  userClaimTxt: {
    fontSize: "16px",
    padding: "24px 0 16px"
  },
  userClaimNum: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  userNoClaimTxt: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  userClaimBtnWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px"
  },
  userClaimBtn: {
    width: "200px",
    height: "50px",
    lineHeight: "50px",
    fontSize: "20px",
    background: "#FFFFFF",
    borderRadius: "25px",
    color: "#4A4FE2",
  },
  claimToken: {
    padding: '100px 0 50px',
    fontSize: "14px",
    lineHeight: "26px",
    wordBreak: "break-all",
    maxWidth:'700px',
    margin: '0 auto',
  },
  claimLink:{
    color: "#A0A3FE",
    textDecoration: "underline",
    '&:hover':{
      color: "#A0A3FE",
    }
  }
})

export default sectionClaimStyle;