import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import sectionFooterStyle from "../jss/sections/sectionFooterStyle";

import { partnersTab } from "../../../features/configure";
const useStyles = makeStyles(sectionFooterStyle);
export default function SectionFooter() {
  const classes = useStyles();
  return (
    <div className={classes.partners}>
      <div className={classes.partnersTitle}>Partners</div>
      <div className={classes.partnersList}>
        <Grid container alignItems="center" spacing={2} >
          {
            partnersTab.map((item) => {
              return (
                <Grid item xs={6} md={2} container justify='center' alignItems="center" key={item.title}>
                  <a className={classes.extraContent} rel="noopener noreferrer" href={item.link} target="_blank">
                    <img src={item.img} alt='' className={classes.partnersImg} />
                  </a>
                </Grid>
              )
            })
          }

          {/* <Grid item xs={6} md={2} container justify='center' alignItems="center">
            <a className={classes.extraContent} rel="noopener noreferrer" href={'https://app.coverprotocol.com/app/marketplace/protocols/BTFINANCE'} target="_blank">
              <img src={require('../../../images/partners/1.png')} alt='' className={classes.partnersImg} />
            </a>
          </Grid>
          <Grid item xs={6} md={2} container justify='center' alignItems="center">
            <a className={classes.extraContent} rel="noopener noreferrer" href={'https://debank.com/projects/btfinance'} target="_blank">
              <img src={require('../../../images/partners/2.png')} alt='' className={classes.partnersImg} />
            </a>
          </Grid>
          <Grid item xs={6} md={2} container justify='center' alignItems="center">
            <a className={classes.extraContent} rel="noopener noreferrer" href={'https://defillama.com/protocol/bt.finance'} target="_blank">
              <img src={require('../../../images/partners/3.png')} alt='' className={classes.partnersImg} />
            </a>
          </Grid>
          <Grid item xs={6} md={2} container justify='center' alignItems="center">
            <a className={classes.extraContent} rel="noopener noreferrer" href={'https://coinmarketcap.com/currencies/bt-finance/'} target="_blank">
              <img src={require('../../../images/partners/4.jpg')} alt='' className={classes.partnersImg} />
            </a>
          </Grid>
          <Grid item xs={6} md={2} container justify='center' alignItems="center">
            <a className={classes.extraContent} rel="noopener noreferrer" href={'https://www.coingecko.com/en/coins/bt-finance'} target="_blank">
              <img src={require('../../../images/partners/5.jpg')} alt='' className={classes.partnersImg} />
            </a>
          </Grid> */}
        </Grid>
      </div>
    </div>
  )
}
