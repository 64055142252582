//  connectWallet
export const HOME_CONNECT_WALLET_BEGIN = 'HOME_CONNECT_WALLET_BEGIN'
export const HOME_CONNECT_WALLET_SUCCESS = 'HOME_CONNECT_WALLET_SUCCESS'
export const HOME_CONNECT_WALLET_FAILURE = 'HOME_CONNECT_WALLET_FAILURE'
export const HOME_ACCOUNTS_CHANGED = 'HOME_ACCOUNTS_CHANGED';
export const HOME_NETWORK_CHANGED = 'HOME_NETWORK_CHANGED'
//  disconnectWallet
export const HOME_DISCONNECT_WALLET_BEGIN = 'HOME_DISCONNECT_WALLET_BEGIN'
export const HOME_DISCONNECT_WALLET_SUCCESS = 'HOME_DISCONNECT_WALLET_SUCCESS'
export const HOME_DISCONNECT_WALLET_FAILURE = 'HOME_DISCONNECT_WALLET_FAILURE'
//  fetchBalance
export const HOME_FETCH_BALANCE_BEGIN = 'HOME_FETCH_BALANCE_BEGIN'
export const HOME_FETCH_BALANCE_SUCCESS = 'HOME_FETCH_BALANCE_SUCCESS'
export const HOME_FETCH_BALANCE_FAILURE = 'HOME_FETCH_BALANCE_FAILURE'
// apy


export const HOME_FETCH_APY_BEGIN = 'HOME_FETCH_APY_BEGIN'
export const HOME_FETCH_APY_SUCCESS = 'HOME_FETCH_APY_SUCCESS'
export const HOME_FETCH_APY_FAILURE = 'HOME_FETCH_APY_FAILURE'
//  fetchStatistics
export const HOME_FETCH_STATISTICS_BEGIN = 'HOME_FETCH_STATISTICS_BEGIN';
export const HOME_FETCH_STATISTICS_SUCCESS = 'HOME_FETCH_STATISTICS_SUCCESS';
export const HOME_FETCH_STATISTICS_FAILURE = 'HOME_FETCH_STATISTICS_FAILURE';