


/* eslint-disable */
import React, { useState, useEffect,useRef } from 'react';
import { renderIcon } from '@download/blockies'
import Button from "components/CustomButtons/Button.js";
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from "@material-ui/core/styles";


import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
const useStyles = makeStyles(styles);

export default function ConnectWallet(props) {
  const {  connected, address, connectWallet, disconnectWallet } = props;

  const classes = useStyles();
  const [shortAddress, setShortAddress] = useState('');
  const [dataUrl, setDataUrl] = useState(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    if (!connected) return;
    const canvas = canvasRef.current
    renderIcon({ seed: address.toLowerCase() }, canvas)
    const updatedDataUrl = canvas.toDataURL()
    if (updatedDataUrl !== dataUrl) {
      setDataUrl(updatedDataUrl)
    }
    if (address.length < 11) {
      setShortAddress(address)
    } else {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`)
    }
  }, [dataUrl, address])
  return (
    <Button
      style={{
        width: '180px',
        margin: '9px 0',
        padding: '6px 0',
        fontSize: '14px',
        fontWeight: 'bold',
        backgroundColor: '#4A4FE2',
        color: '#fff',
        boxShadow: '0 2px 2px 0 rgba(53, 56, 72, 0.14), 0 3px 1px -2px rgba(53, 56, 72, 0.2), 0 1px 5px 0 rgba(53, 56, 72, 0.12)',
      }}
      className={classes.Button}
      type="button"
      color="primary"
      onClick={connected ? disconnectWallet : connectWallet}
    >
      {connected ? (
        <>
          <canvas ref={canvasRef} style={{ display: 'none' }} />
          <Avatar alt="address" src={dataUrl} style={{
            width: '24px',
            height: "24px",
            marginRight: '10px',
          }} />{shortAddress}
        </>
      ) : (
          <>
            Connect your wallet
          </>
        )}
    </Button>
  )
}