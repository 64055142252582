import App from '../features/App';
import { PageNotFound } from '../features/common';
import homeRoute from '../features/home/route';
import { Home } from '../features/home';
import stakeRoute from '../features/stake/route';
import poolRoute from '../features/stake/poolRoute';
// import farmRoute from '../features/farm/route';
import commonRoute from '../features/common/route';
import vaultRoute from '../features/vault/route';
// import zapRoute from '../features/swap/route'; //
import claimRoute from '../features/claim/route';
import { Claim } from '../features/claim';

import _ from 'lodash';

// NOTE: DO NOT CHANGE the 'childRoutes' name and the declaration pattern.
// This is used for Rekit cmds to register routes config for new features, and remove config when remove features, etc.
// const childRoutes = [
//   vaultRoute,
//   stakeRoute,
//   commonRoute,
//   // farmRoute
//   // zapRoute,
// ];

const claimRoutes = [
  claimRoute
];

const homeRoutes = [
  homeRoute,
  commonRoute
];

const vaultRoutes = [
  vaultRoute,
  commonRoute
];


const stakeRoutes = [
  stakeRoute,
  commonRoute
];

const poolRoutes = [
  poolRoute,
  commonRoute
];



const routes = [
  {
    path: '/',
    component: Home,
    childRoutes: [
      ...homeRoutes,
      { path: '*', name: 'Page not found', component: PageNotFound },
    ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
  },
  {
    path: '/claim',
    component: Claim,
    childRoutes: [
      ...claimRoutes,
      { path: '*', name: 'Page not found', component: PageNotFound },
    ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
  },
  {
    path: '/vault',
    component: App,
    childRoutes: [
      ...vaultRoutes,
      { path: '*', name: 'Page not found', component: PageNotFound },
    ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
  },
  {
    path: '/stake',
    component: App,
    childRoutes: [
      ...stakeRoutes,
      { path: '*', name: 'Page not found', component: PageNotFound },
    ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
  },
  {
    path: '/pool',
    component: App,
    childRoutes: [
      ...poolRoutes,
      { path: '*', name: 'Page not found', component: PageNotFound },
    ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
  }
];
// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.


function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = _.find(route.childRoutes, (child => child.isIndex));
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = '';
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}


routes.forEach(handleIndexRoute);

export default routes;
