import { MerkleDistributorAbi, BTU } from "../configure";
import { enqueueSnackbar } from '../common/redux/actions';


export const claim = async ({ web3, address, index, amount, merkleProof, dispatch }) => {

  const contract = new web3.eth.Contract(MerkleDistributorAbi, BTU.token);
  const data = await _claim({ web3, contract, index, amount, address, merkleProof, dispatch });
  return data;
}

const _claim = ({ web3, contract, index, amount, address, merkleProof, dispatch }) => {
  return new Promise((resolve, reject) => {
    contract.methods.claim(index, address, amount, merkleProof).send({ from: address }).on('transactionHash', function (hash) {
      dispatch(enqueueSnackbar({
        message: hash,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success'
        },
        hash
      }));
    })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve(receipt)
      })
      .on('error', function (error) {
        console.log(error)
        reject(error)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })
}
