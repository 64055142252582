//  fetchBalances
export const VAULT_FETCH_BALANCES_BEGIN = 'VAULT_FETCH_BALANCES_BEGIN';
export const VAULT_FETCH_BALANCES_SUCCESS = 'VAULT_FETCH_BALANCES_SUCCESS';
export const VAULT_FETCH_BALANCES_FAILURE = 'VAULT_FETCH_BALANCES_FAILURE';
//  fetchBalance
export const VAULT_FETCH_BALANCE_BEGIN = 'VAULT_FETCH_BALANCE_BEGIN';
export const VAULT_FETCH_BALANCE_SUCCESS = 'VAULT_FETCH_BALANCE_SUCCESS';
export const VAULT_FETCH_BALANCE_FAILURE = 'VAULT_FETCH_BALANCE_FAILURE';
//  fetchPoolBalances
export const VAULT_FETCH_POOL_BALANCES_BEGIN = 'VAULT_FETCH_POOL_BALANCES_BEGIN';
export const VAULT_FETCH_POOL_BALANCES_SUCCESS = 'VAULT_FETCH_POOL_BALANCES_SUCCESS';
export const VAULT_FETCH_POOL_BALANCES_FAILURE = 'VAULT_FETCH_POOL_BALANCES_FAILURE';
//  fetchApproval
export const VAULT_FETCH_APPROVAL_BEGIN = 'VAULT_FETCH_APPROVAL_BEGIN';
export const VAULT_FETCH_APPROVAL_SUCCESS = 'VAULT_FETCH_APPROVAL_SUCCESS';
export const VAULT_FETCH_APPROVAL_FAILURE = 'VAULT_FETCH_APPROVAL_FAILURE';
//  fetchDeposit
export const VAULT_FETCH_DEPOSIT_BEGIN = 'VAULT_FETCH_DEPOSIT_BEGIN';
export const VAULT_FETCH_DEPOSIT_SUCCESS = 'VAULT_FETCH_DEPOSIT_SUCCESS';
export const VAULT_FETCH_DEPOSIT_FAILURE = 'VAULT_FETCH_DEPOSIT_FAILURE';
//  fetchWithdraw
export const VAULT_FETCH_WITHDRAW_BEGIN = 'VAULT_FETCH_WITHDRAW_BEGIN';
export const VAULT_FETCH_WITHDRAW_SUCCESS = 'VAULT_FETCH_WITHDRAW_SUCCESS';
export const VAULT_FETCH_WITHDRAW_FAILURE = 'VAULT_FETCH_WITHDRAW_FAILURE';
//  fetchUniswapPrices
export const VAULT_FETCH_CONTRACT_APY_BEGIN = 'VAULT_FETCH_CONTRACT_APY_BEGIN';
export const VAULT_FETCH_CONTRACT_APY_SUCCESS = 'VAULT_FETCH_CONTRACT_APY_SUCCESS';
export const VAULT_FETCH_CONTRACT_APY_FAILURE = 'VAULT_FETCH_CONTRACT_APY_FAILURE';
//  fetchStatistics
export const VAULT_FETCH_STATISTICS_BEGIN = 'VAULT_FETCH_STATISTICS_BEGIN';
export const VAULT_FETCH_STATISTICS_SUCCESS = 'VAULT_FETCH_STATISTICS_SUCCESS';
export const VAULT_FETCH_STATISTICS_FAILURE = 'VAULT_FETCH_STATISTICS_FAILURE';
//

export const VAULT_FETCH_STAKE_BALANCE_BEGIN = 'VAULT_FETCH_STAKE_BALANCE_BEGIN';
export const VAULT_FETCH_STAKE_BALANCE_SUCCESS = 'VAULT_FETCH_STAKE_BALANCE_SUCCESS';
export const VAULT_FETCH_STAKE_BALANCE_FAILURE = 'VAULT_FETCH_STAKE_BALANCE_FAILURE';


export const VAULT_FETCH_VAULTS_BEGIN = 'VAULT_FETCH_VAULTS_BEGIN';
export const VAULT_FETCH_VAULTS_SUCCESS = 'VAULT_FETCH_VAULTS_SUCCESS';
export const VAULT_FETCH_VAULTS_FAILURE = 'VAULT_FETCH_VAULTS_FAILURE';