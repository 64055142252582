/* eslint-disable */
import React, { useState, useEffect } from 'react';
import BigNumber from "bignumber.js";
import { byDecimals, calculateReallyNum, forMatVal, toNonExponential, forMatInvestedValue } from 'features/helpers/bignumber';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions'
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { primaryColor } from "assets/jss/material-kit-pro-react.js";
// core components
import Button from "components/CustomButtons/Button.js";
import Avatar from '@material-ui/core/Avatar';
import { useSnackbar } from 'notistack';
//  hooks
import { useConnectWallet, useFetchApy } from '../../home/redux/hooks';
import { useCheckDepositApproval, useFetchDepositApproval, useFetchDepositStake } from '../../stake/redux/hooks';
import { useFetchBalances, useFetchPoolsBalances, useFetchApproval, useFetchDeposit, useFetchWithdraw, useFetchStakeBalance, useFetchVaults } from '../redux/hooks';
import CustomSlider from 'components/CustomSlider/CustomSlider';

import sectionPoolsStyle from "../jss/sections/sectionPoolsStyle";
import { inputLimitPass, inputFinalVal, isEmpty } from 'features/helpers/utils';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(sectionPoolsStyle);

export default function SectionHightPools() {
  const { web3, address, networkId } = useConnectWallet();
  let { poolsList, fetchPoolsBalances } = useFetchPoolsBalances();
  const { tokens, fetchBalances } = useFetchBalances();
  const [openedCardList, setOpenCardList] = useState([]);
  const classes = useStyles();
  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const { fetchDeposit, fetchDepositEth, fetchDepositPending } = useFetchDeposit();
  const { fetchWithdraw, fetchWithdrawEth, fetchWithdrawPending } = useFetchWithdraw();
  const { stakeTokens, fetchStakeBalance, fetchStakeBalancePending } = useFetchStakeBalance();

  const { rewardpools, vaults, fetchApy } = useFetchApy();
  const { vaultsList, fetchVaults } = useFetchVaults();

  const { checkDepositApproval } = useCheckDepositApproval();
  const { fetchDepositApproval, fetchDeposotApprovalPending } = useFetchDepositApproval();
  const { fetchDepositStake, fetchStakePending } = useFetchDepositStake();

  const [depositedBalance, setDepositedBalance] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const changeDetailInputValue = (type, id, total, tokenDecimals, dec, event) => {
    const num = forMat(total, dec)
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    let sliderNum = 0;
    let inputVal = Number(forMatVal(value.toString(), dec).replace(',', ''));
    if (value) {
      sliderNum = byDecimals(inputVal / num, 0).toFormat(2) * 100;
    }
    switch (type) {
      case 'depositedBalance':
        setDepositedBalance({
          ...depositedBalance,
          [id]: inputFinalVal(value, num, dec, tokenDecimals),
          [`slider-${id}`]: sliderNum,
        });
        break;
      case 'withdrawAmount':
        setWithdrawAmount({
          ...withdrawAmount,
          [id]: inputFinalVal(value, num, dec, tokenDecimals),
          [`slider-${id}`]: sliderNum,
        });
        break;
      default:
        break;
    }
  }

  const handleDepositedBalance = (id, total, dec, event, sliderNum) => {
    const num = forMat(total, dec)
    setDepositedBalance({
      ...depositedBalance,
      [id]: sliderNum == 0 ? 0 : calculateReallyNum(num, sliderNum, dec),
      [`slider-${id}`]: sliderNum == 0 ? 0 : sliderNum,
    });
  }

  const handleWithdrawAmount = (id, total, dec, event, sliderNum) => {
    const num = forMat(total, dec)
    setWithdrawAmount({
      ...withdrawAmount,
      [id]: sliderNum == 0 ? 0 : calculateReallyNum(num, sliderNum, dec),
      [`slider-${id}`]: sliderNum == 0 ? 0 : sliderNum,
    });
  };

  const onApproval = (pool, id, event) => {
    event.stopPropagation();
    fetchApproval({
      address,
      web3,
      tokenAddress: pool.tokenAddress,
      contractAddress: pool.earnContractAddress,
      index: id
    }).then(
      () => enqueueSnackbar(`Approval success`, { variant: 'success' })
    ).catch(
      error => enqueueSnackbar(`Approval error: ${error}`, { variant: 'error' })
    )
  }

  const onDeposit = (pool, id, isAll, balanceSingle, event) => {
    event.stopPropagation();
    if (isAll) {
      setDepositedBalance({
        ...depositedBalance,
        [id]: forMat(balanceSingle, pool.valueDecimals),
        [`slider-${id}`]: 100,
      })
    }
    let amountValue = depositedBalance[id] ? depositedBalance[id].toString().replace(',', '') : depositedBalance[id];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    if (!Boolean(forMat(balanceSingle, pool.valueDecimals)) && isAll) {
      return false
    }
    if (Number(amountValue) <= 0 && !isAll) {
      return false
    }
    if (!pool.tokenAddress) {// 如果是eth
      fetchDepositEth({
        address,
        web3,
        amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
        contractAddress: pool.earnContractAddress,
        index: id
      }).then(
        () => enqueueSnackbar(`Deposit success`, { variant: 'success' })
      ).catch(
        error => enqueueSnackbar(`Deposit error: ${error}`, { variant: 'error' })
      )
    } else {
      fetchDeposit({
        address,
        web3,
        isAll,
        amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
        contractAddress: pool.earnContractAddress,
        index: id
      }).then((res) => {
        enqueueSnackbar(`Deposit success`, { variant: 'success' })
      }
      ).catch(
        error => enqueueSnackbar(`Deposit error: ${error}`, { variant: 'error' })
      )
    }
  }
  const onDepositStake = (pool, id, event) => {
    event.stopPropagation();
    let amountValue = depositedBalance[id] ? depositedBalance[id].replace(',', '') : depositedBalance[id];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    const amount = new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10)
    if (Number(amount) <= 0) {
      return false
    }
    checkDepositApproval({ address, web3, pool }).then(() => {

      if (!pool.tokenAddress) {// 如果是eth
        fetchDepositEth({
          address,
          web3,
          amount,
          contractAddress: pool.earnContractAddress,
          index: id
        }).then((res) => {
          enqueueSnackbar(`Deposit success`, { variant: 'success' })
          if (Boolean(pool.stakeAllowance === 0)) {
            fetchDepositApproval({ address, web3, pool, index: id }).then(() => {
              fetchDepositStake({ address, web3, pool, index: id })
            })
          } else {
            fetchDepositStake({ address, web3, pool, index: id })
          }
        }).catch(
          error => enqueueSnackbar(`Deposit error: ${error}`, { variant: 'error' })
        )
      } else {
        fetchDeposit({
          address,
          web3,
          isAll: false,
          amount,
          contractAddress: pool.earnContractAddress,
          index: id
        }).then((res) => {
          enqueueSnackbar(`Deposit success`, { variant: 'success' })
          if (Boolean(pool.stakeAllowance === 0)) {
            fetchDepositApproval({ address, web3, pool, index: id }).then(() => {
              fetchDepositStake({ address, web3, pool, index: id })
            })
          } else {
            fetchDepositStake({ address, web3, pool, index: id })
          }
        }
        ).catch(
          error => enqueueSnackbar(`Deposit error: ${error}`, { variant: 'error' })
        )
      }

      // fetchDeposit({
      //   address,
      //   web3,
      //   isAll: false,
      //   amount,
      //   contractAddress: pool.earnContractAddress,
      //   index: id
      // }).then((res) => {
      //   enqueueSnackbar(`Deposit success`, { variant: 'success' })
      //   if (Boolean(pool.stakeAllowance === 0)) {
      //     fetchDepositApproval({ address, web3, pool, index: id }).then(() => {
      //       fetchDepositStake({ address, web3, pool, index: id })
      //     })
      //   } else {
      //     fetchDepositStake({ address, web3, pool, index: id })
      //   }
      // }).catch(
      //   error => enqueueSnackbar(`Deposit error: ${error}`, { variant: 'error' })
      // )
    })
  }

  const onWithdraw = (pool, id, isAll, singleDepositedBalance, event) => {
    event.stopPropagation();
    // console.log(isAll)
    if (isAll) {
      setWithdrawAmount({
        ...withdrawAmount,
        [id]: forMat(singleDepositedBalance, pool.valueDecimals),
        [`slider-${id}`]: 100,
      })
    }
    let amountValue = withdrawAmount[id] ? withdrawAmount[id].toString().replace(',', '') : withdrawAmount[id];
    if (amountValue == undefined) {
      amountValue = '0';
    }
    if (!Boolean(forMat(singleDepositedBalance, pool.valueDecimals)) && isAll) {
      return false
    }
    if (Number(amountValue) <= 0 && !isAll) {
      return false
    }
    if (!pool.tokenAddress) {// 如果是eth
      fetchWithdrawEth({
        address,
        web3,
        isAll,
        amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
        contractAddress: pool.earnContractAddress,
        index: id
      }).then(
        () => enqueueSnackbar(`Withdraw success`, { variant: 'success' })
      ).catch(
        error => enqueueSnackbar(`Withdraw error: ${error}`, { variant: 'error' })
      )
    } else {
      fetchWithdraw({
        address,
        web3,
        isAll,
        amount: new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10),
        contractAddress: pool.earnContractAddress,
        index: id
      }).then(
        () => enqueueSnackbar(`Withdraw success`, { variant: 'success' })
      ).catch(
        error => enqueueSnackbar(`Withdraw error: ${error}`, { variant: 'error' })
      )
    }
  }

  const openCard = (pool) => {
    return setOpenCardList(
      openedCardList => {
        if (openedCardList.includes(pool.id)) {
          return openedCardList.filter(item => item !== pool.id)
        } else {
          return [...openedCardList, pool.id]
        }
      }
    )
  }
  useEffect(() => {
    if (address && web3) {
      fetchBalances({ address, web3, tokens });
      fetchPoolsBalances({ address, web3, poolsList });
      fetchStakeBalance({ address, web3, stakeTokens });
      const id = setInterval(() => {
        fetchBalances({ address, web3, tokens });
        fetchStakeBalance({ address, web3, stakeTokens });
        fetchPoolsBalances({ address, web3, poolsList });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, web3, fetchBalances, fetchPoolsBalances]);

  useEffect(() => {
    fetchApy();
    fetchVaults()
    const id = setInterval(() => {
      fetchApy();
      fetchVaults()
    }, 10000);
    return () => clearInterval(id);
  }, [poolsList, fetchApy, fetchVaults]);

  const forMat = (number, dec = 4) => {
    return toNonExponential(new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10).exponentiatedBy(dec)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10).exponentiatedBy(dec)
    ).toNumber())
  }

  const isMoreDepostLimit = (value, depostLimit) => {
    if (isEmpty(value) || depostLimit == 0 || value < depostLimit) {
      return false
    }
    return true;
  }

  return (
    <>
      {
        poolsList.map((list, indexKey) => {
          const { mainTitle, secondTitle, color, pools } = list;
          return (
            <Grid container key={indexKey} style={{ paddingTop: '4px' }}>
              <Grid container style={{ paddingTop: '4px' }}>
                <Grid item xs={12}>
                  <div className={classes.headTitleWrap}>
                    <div className={classes.headTitle} style={{ borderLeft: `5px solid ${color}` }} >{mainTitle}</div>
                    <div className={classes.headDesc}>{secondTitle}</div>
                  </div>
                </Grid>
              </Grid>
              <div style={{ width: "100%" }}>

                <div className={classes.listWrap}>
                  <Grid container alignItems="center" justify="space-around" spacing={2} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <Grid item md={3} xs={4}>
                      <div style={{ padding: "0 8px",marginLeft:'44px' }}>Asset </div>
                    </Grid>
                    <Grid item md={8} xs={8} >
                      <Grid item container spacing={2} justify="space-between">
                        <Grid item xs={6} md={3} container alignItems="center">APY</Grid>
                        <Grid item xs={6} md={3} container alignItems="center">TVL</Grid>
                        <Hidden mdDown>
                          <Grid item md={3} container alignItems="center">Deposited</Grid>
                        </Hidden>
                        <Hidden mdDown>
                          <Grid item md={3} container alignItems="center">Staked Value</Grid>
                        </Hidden>
                      </Grid>
                    </Grid>
                    <Grid item md={1} ></Grid>
                  </Grid>
                </div>
              </div>
              { pools.map((pool, index) => {
                let balanceSingle = byDecimals(tokens[pool.tokenAddress].tokenBalance, pool.tokenDecimals);
                let singleDepositedBalance = byDecimals(tokens[pool.earnedTokenAddress].tokenBalance, pool.itokenDecimals);
                let depositedApy = `${vaults[pool.vaultsApy] || 0}`;
                let stakeApy = `${rewardpools[pool.stakeApy] || 0}`;
                let stakeBalancs = pool.isStake === 1 ? byDecimals(stakeTokens[pool.earnedTokenAddress].stakeBalance, pool.itokenDecimals) : 0
                let investedValue = `${vaultsList[pool.earnedTokenAddress] ? vaultsList[pool.earnedTokenAddress].totalLockedValue : 0}`
                return (
                  <Grid item xs={12} container key={index} style={{ marginBottom: "16px" }} spacing={0}>
                    <div style={{ width: "100%" }}>
                      <Accordion
                        expanded={Boolean(openedCardList.includes(pool.id))}
                        className={classes.accordion}
                        TransitionProps={{ unmountOnExit: true }}
                      >
                        <AccordionSummary
                          className={classes.details}
                          style={{ justifyContent: "space-between" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            openCard(pool)
                          }}
                        >
                          <Grid container alignItems="center" justify="space-around" spacing={2} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                            <Grid item md={3} xs={4}>
                              <Grid container justify="space-between" spacing={2}>

                                <Hidden mdDown>
                                  <Grid item >
                                    <Avatar
                                      alt={pool.token}
                                      style={{ width: '36px',height:'36px' }}
                                      src={require(`../../../images/${pool.token}-logo.png`)}
                                    />
                                  </Grid>
                                </Hidden>
                                <Grid item style={{ flex: 1, width: '100px' }} container justify='center' alignItems="center">
                                  <Grid item style={{ width: "100%" }}>
                                    <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>
                                      {pool.token}
                                      <Hidden smUp>
                                        <i
                                          style={{ color: primaryColor[0], marginLeft: '4px', visibility: Boolean(pool.tokenDescriptionUrl) ? "visible" : "hidden" }}
                                          className={"yfiiicon yfii-help-circle"}
                                          onClick={
                                            event => {
                                              event.stopPropagation();
                                              window.open(pool.tokenDescriptionUrl)
                                            }
                                          }
                                        />
                                      </Hidden>
                                    </Typography>

                                    {/* <Typography className={classes.iconContainerSubTitle} variant="body2" noWrap>{pool.token}</Typography> */}
                                  </Grid>
                                </Grid>

                              </Grid>
                            </Grid>

                            <Grid item md={8} xs={8} >
                              <Grid item container spacing={2} justify="space-between">
                                <Grid item xs={6} md={3} container alignItems="center">
                                  {
                                    pool.isStake === 1 ? (
                                      <Grid item>
                                        <Tooltip title={`Auto harvested ${depositedApy}% + BT ${stakeApy}%`} arrow placement="top" >
                                          <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>
                                            {(Number(depositedApy) + Number(stakeApy)).toFixed(2)}%
                                          </Typography>
                                        </Tooltip>
                                        {/* <Typography className={classes.iconContainerSubTitle} variant="body2" noWrap>Total APY</Typography> */}
                                      </Grid>
                                    ) : (
                                        <Grid item>
                                          <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap> {depositedApy}%</Typography>
                                          {/* <Typography className={classes.iconContainerSubTitle} variant="body2" noWrap>APY</Typography> */}
                                        </Grid>
                                      )
                                  }
                                </Grid>
                                <Grid item xs={6} md={3} container justify='center' alignItems="center">
                                  <Grid item style={{ width: "100%" }}>
                                    <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>{forMatInvestedValue(investedValue)}</Typography>
                                    {/* <Typography className={classes.iconContainerSubTitle} variant="body2" noWrap>Pool($)</Typography> */}
                                  </Grid>
                                </Grid>
                                <Hidden mdDown>
                                  <Grid item md={3} container justify='center' alignItems="center">
                                    <Grid item style={{ width: "100%" }}>
                                      <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>{forMat(singleDepositedBalance, pool.valueDecimals)} {pool.token}</Typography>
                                      {/* <Typography className={classes.iconContainerSubTitle} variant="body2" noWrap>Deposit</Typography> */}
                                    </Grid>
                                  </Grid>
                                </Hidden>
                                <Hidden mdDown>
                                  <Grid item md={3} container justify='center' alignItems="center">
                                    {pool.isStake === 1 && (
                                      <Grid item style={{ width: "100%" }}>
                                        <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>
                                          {forMat(stakeBalancs.multipliedBy(new BigNumber(pool.pricePerFullShare)), pool.valueDecimals)} {pool.token}
                                        </Typography>
                                        {/* <Typography className={classes.iconContainerSubTitle} variant="body2" noWrap>Staked Value</Typography> */}
                                      </Grid>
                                    )}
                                  </Grid>
                                </Hidden>
                              </Grid>
                            </Grid>

                            <Grid item md={1} >
                              <Grid item container justify="flex-end" alignItems="center" spacing={2}>
                                <Grid item>
                                  <div className={classes.detailsBtnWrap} onClick={(event) => { event.stopPropagation(); openCard(pool); }}>
                                    {
                                      openedCardList.includes(pool.id) ?
                                        <div className={classes.detailsBtn}>
                                          <span>Detail</span> <img src={require(`../../../images/less_icon.png`)} className={classes.iconImg} />
                                        </div>
                                        :
                                        <div className={classes.detailsBtn}>
                                          <span>Detail</span> <img src={require(`../../../images/more_icon.png`)} className={classes.iconImg} />
                                        </div>
                                    }
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails style={{ justifyContent: "space-between" }}>
                          <Grid container style={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
                            <Grid item xs={12} sm={6} className={classes.sliderDetailContainer}>
                              <div className={classes.showDetailRight}>
                                balance:{forMat(balanceSingle, pool.valueDecimals)} {pool.token}
                              </div>
                              <FormControl fullWidth variant="outlined">
                                <div style={{ background: "#F8F9FA", borderRadius: "12px", color: '#2D2E35' }}>
                                  <CustomOutlinedInput
                                    value={depositedBalance[pool.id] != undefined ? depositedBalance[pool.id] : '0'}
                                    onChange={changeDetailInputValue.bind(this, 'depositedBalance', pool.id, balanceSingle.toNumber(), pool.tokenDecimals, pool.valueDecimals)}
                                  />
                                </div>
                              </FormControl>
                              <div className={classes.poolTips}></div>
                              <div className={classes.sliderWrap}>
                                <CustomSlider
                                  classes={{
                                    root: classes.depositedBalanceSliderRoot,
                                    markLabel: classes.depositedBalanceSliderMarkLabel,
                                  }}
                                  aria-labelledby="continuous-slider"
                                  value={depositedBalance['slider-' + pool.id] ? depositedBalance['slider-' + pool.id] : 0}
                                  onChange={handleDepositedBalance.bind(this, pool.id, balanceSingle.toNumber(), pool.valueDecimals)}
                                />
                              </div>
                              <div>
                                {
                                  pool.allowance === 0 ? (
                                    <div className={classes.showDetailButtonCon}>
                                      <Button
                                        onClick={onApproval.bind(this, pool, pool.id)}
                                        disabled={fetchApprovalPending[pool.id]}
                                      >
                                        {fetchApprovalPending[pool.id] ? `Approve...` : `Approve`}
                                      </Button>
                                    </div>
                                  ) : (
                                      <div className={classes.showDetailButtonCon}>
                                        <Button
                                          onFocus={(event) => event.stopPropagation()}
                                          disabled={
                                            fetchDepositPending[pool.id] || (new BigNumber(depositedBalance[pool.id]).toNumber() > balanceSingle.toNumber() ||
                                              isMoreDepostLimit(new BigNumber(depositedBalance[pool.id]).toNumber(), pool.depostLimit))
                                          }
                                          onClick={onDeposit.bind(this, pool, pool.id, false, balanceSingle)}
                                        >Deposit
                                        </Button>
                                        {
                                          pool.isStake === 1 ? (
                                            <Button
                                              onFocus={(event) => event.stopPropagation()}
                                              disabled={
                                                fetchDepositPending[pool.id] || (new BigNumber(depositedBalance[pool.id]).toNumber() > balanceSingle.toNumber() ||
                                                  isMoreDepostLimit(new BigNumber(depositedBalance[pool.id]).toNumber(), pool.depostLimit))
                                              }
                                              onClick={onDepositStake.bind(this, pool, pool.id)}
                                            >{'Deposit & Stake'}
                                            </Button>
                                          ) : (
                                              <>
                                                {Boolean(pool.tokenAddress) && <Button
                                                  onFocus={(event) => event.stopPropagation()}
                                                  disabled={
                                                    fetchDepositPending[pool.id] || (new BigNumber(depositedBalance[pool.id]).toNumber() > balanceSingle.toNumber() ||
                                                      isMoreDepostLimit(balanceSingle.toNumber(), pool.depostLimit))
                                                  }
                                                  onClick={onDeposit.bind(this, pool, pool.id, true, balanceSingle)}
                                                >Deposit All
                                              </Button>}
                                              </>
                                            )
                                        }

                                      </div>
                                    )
                                }
                              </div>
                            </Grid>

                            <Grid item xs={12} sm={6} className={classes.sliderDetailContainer}>
                              <div className={classes.showDetailRight}>
                                {forMat(singleDepositedBalance.multipliedBy(new BigNumber(pool.pricePerFullShare)), pool.valueDecimals)} {pool.token}
                                ({forMat(singleDepositedBalance, pool.valueDecimals)} {pool.earnedToken})
                              </div>
                              <FormControl fullWidth variant="outlined">
                                <div style={{ background: "#F8F9FA", borderRadius: "12px", color: '#2D2E35' }}>
                                  <CustomOutlinedInput
                                    value={withdrawAmount[pool.id] != undefined ? withdrawAmount[pool.id] : '0'}
                                    onChange={changeDetailInputValue.bind(this, 'withdrawAmount', pool.id, singleDepositedBalance.toNumber(), pool.tokenDecimals, pool.valueDecimals)}
                                  />
                                </div>
                              </FormControl>
                              <div className={classes.poolTips}>10% within 24 hours, 0.3% within a week,0.1% after a week.</div>
                              <div className={classes.sliderWrap}>
                                <CustomSlider
                                  classes={{
                                    root: classes.drawSliderRoot,
                                    markLabel: classes.drawSliderMarkLabel,
                                  }}
                                  aria-labelledby="continuous-slider"
                                  value={withdrawAmount['slider-' + pool.id] ? withdrawAmount['slider-' + pool.id] : 0}
                                  onChange={handleWithdrawAmount.bind(this, pool.id, singleDepositedBalance.toNumber(), pool.valueDecimals)}
                                />
                              </div>
                              <div className={classes.showDetailButtonCon}>
                                <Button
                                  disabled={fetchWithdrawPending[pool.id] || !Boolean(withdrawAmount[pool.id])}
                                  onClick={onWithdraw.bind(this, pool, pool.id, false, singleDepositedBalance)}
                                >
                                  {fetchWithdrawPending[pool.id] ? `Withdraw...` : `Withdraw`}
                                </Button>
                                <Button
                                  onClick={onWithdraw.bind(this, pool, pool.id, true, singleDepositedBalance)}
                                >
                                  {fetchWithdrawPending[pool.id] ? `Withdraw...` : `Withdraw All`}
                                </Button>
                              </div>
                            </Grid>

                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Grid>
                )
              })}

            </Grid>
          )
        })
      }
    </>

  )
}
