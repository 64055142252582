import { btRewardsContractABI, btAutoStakeContractABI } from "./index";
// id: '池子id',
// name: '池子名字',  
// token: '池子代币',
// tokenDescription: '代币描述',
// tokenAddress: '代币ERC20地址',
// tokenDecimals: '存入精度'
// itokenDecimals: '提取精度'
// depostLimit: '存入最大数量限制' 0时不限制
// earnedToken: '奖励代币',
// earnedTokenAddress: '奖励代币ERC20地址',
// earnContractAddress: '池子合约地址',
// price ： 挖的代币的价格！
// path price: 
// isStake: 1 ture 是否开启stake界面
// stakeName    抵押什么得什么
// stakeEarnContractAddress 对应的pool地址

export const poolsList = [
  {
    mainTitle: 'BT Vaults V2 Pools',
    secondTitle: 'V2 has fixed all exploit bugs, the V1 $BT rewards are coming to an end, please migrate your funds from V1 below to V2.',
    color: '#FF8820',
    pools: [
      {
        id: 'eth',
        name: 'ETH',
        token: 'ETH',
        tokenDescription: 'ETH',
        tokenAddress: '',
        tokenDecimals: 18,
        itokenDecimals: 18,
        depostLimit: 0,
        tokenDescriptionUrl: '',
        tokenDescriptionUrl2: '',
        earnedToken: 'bETH',
        valueDecimals: 6,
        earnedTokenAddress: '0x5C31BCe2110779f13B70B9ecB8a96073138e6A53',
        earnContractAddress: '0x5C31BCe2110779f13B70B9ecB8a96073138e6A53',
        defaultApy: "42.63",
        pricePerFullShare: 1,
        pastPricePerFullShare: 1,
        isStake: 1,
        stakeName: 'bETH-BT',
        stakeEarnContractAddress: '0x54e293F48b19Cb79A93648c47F048b1cD6ABf289',
        stakeEarnContractAbi: btRewardsContractABI,
        vaultsApy: "ETH",
        stakeApy: "beth"
      },
      {
        id: 'wbtc',
        name: 'WBTC',
        token: 'WBTC',
        tokenDescription: 'WBTC',
        tokenAddress: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
        tokenDecimals: 8,
        itokenDecimals: 8,
        depostLimit: 0,
        tokenDescriptionUrl: '',
        tokenDescriptionUrl2: '',
        earnedToken: 'bWBTC',
        valueDecimals: 8,
        earnedTokenAddress: '0xD5fD08D2F6Db64Ad88B6083Ba2F46FE8c5E6e053',
        earnContractAddress: '0xD5fD08D2F6Db64Ad88B6083Ba2F46FE8c5E6e053',
        defaultApy: "42.63",
        pricePerFullShare: 1,
        pastPricePerFullShare: 1,
        isStake: 1,
        stakeName: 'bWBTC-BT',
        stakeEarnContractAddress: '0xC4Cba9172D0ac518dB24F3a2391bDA94e4F37617',
        stakeEarnContractAbi: btRewardsContractABI,
        vaultsApy: "WBTC",
        stakeApy: "bwbtc"
      },
    {
        id: 'alusd3crv',
        name: 'alUSD3CRV',
        token: 'alUSD3CRV',
        tokenDescription: 'alUSD3CRV',
        tokenAddress: '0x43b4FdFD4Ff969587185cDB6f0BD875c5Fc83f8c',
        tokenDecimals: 18,
        itokenDecimals: 18,
        depostLimit: 0,
        tokenDescriptionUrl: '',
        tokenDescriptionUrl2: '',
        earnedToken: 'balUSD3CRV',
        valueDecimals: 10,
        earnedTokenAddress: '0x473832D4b91a5f77BB21103631c80BDf0682EBA9',
        earnContractAddress: '0x473832D4b91a5f77BB21103631c80BDf0682EBA9',
        defaultApy: "42.63",
        pricePerFullShare: 1,
        pastPricePerFullShare: 1,
        isStake: 1,
        stakeName:'balUSD3CRV-BT',
        stakeEarnContractAddress: '0x9b8DF24789C6Ae9185B85841dA5F4385DD296258',
        stakeEarnContractAbi: btRewardsContractABI,
        vaultsApy: "alUSD3CRV",
        stakeApy: "balUSD3CRV"
    },
    ]
  },
  {
    mainTitle: 'BT Vaults V1 Pools (Do not deposit) ',
    secondTitle: 'For the security and better returns, please migrate your funds from V1 to V2 above. ',
    color: '#6036F1',
    pools: [
    {
        id: 'uni',
        name: 'UNI',
        token: 'UNI',
        tokenDescription: 'UNI',
        tokenAddress: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
        tokenDecimals: 18,
        itokenDecimals: 18,
        depostLimit: 0,
        tokenDescriptionUrl: '',
        tokenDescriptionUrl2: '',
        earnedToken: 'bUNI',
        valueDecimals: 10,
        earnedTokenAddress: '0x772E9cc4dB702789ca51B5217c2A8F748cF7f0c6',
        earnContractAddress: '0x772E9cc4dB702789ca51B5217c2A8F748cF7f0c6',
        defaultApy: "42.63",
        pricePerFullShare: 1,
        pastPricePerFullShare: 1,
        isStake: 1,
        stakeName: 'bUNI-BT',
        stakeEarnContractAddress: '0xb45B9f0bC3358E878F8c9ab6ab57A8823BB590a0',
        stakeEarnContractAbi: btRewardsContractABI,
        vaultsApy: "UNI V1",
        stakeApy: "buni v1"
      },
      {
        id: 'usdc',
        name: 'USDC',
        token: 'USDC',
        tokenDescription: 'USDC',
        tokenAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        tokenDecimals: 6,
        itokenDecimals: 6,
        depostLimit: 0,
        tokenDescriptionUrl: '',
        tokenDescriptionUrl2: '',
        earnedToken: 'bUSDC',
        valueDecimals: 6,
        earnedTokenAddress: '0x1cB255425D82FD2A49CC169aD708d860237Bd1aB',
        earnContractAddress: '0x1cB255425D82FD2A49CC169aD708d860237Bd1aB',
        defaultApy: "42.63",
        pricePerFullShare: 1,
        pastPricePerFullShare: 1,
        isStake: 1,
        stakeName: 'bUSDC-BT',
        stakeEarnContractAddress: '0x18a73A021fF130F975B13B787fE65c8c29665E83',
        stakeEarnContractAbi: btRewardsContractABI,
        vaultsApy: "USDC V1",
        stakeApy: "busdc v1"
      },
      {
        id: 'usdt',
        name: 'USDT',
        token: 'USDT',
        tokenDescription: 'USDT',
        tokenAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        tokenDecimals: 6,
        itokenDecimals: 6,
        depostLimit: 0,
        tokenDescriptionUrl: '',
        tokenDescriptionUrl2: '',
        earnedToken: 'bUSDT',
        valueDecimals: 6,
        earnedTokenAddress: '0xaf9f57a0dCd02Db2D7c6dEd23dc3a1754cc86200',
        earnContractAddress: '0xaf9f57a0dCd02Db2D7c6dEd23dc3a1754cc86200',
        defaultApy: "42.63",
        pricePerFullShare: 1,
        pastPricePerFullShare: 1,
        isStake: 1,
        stakeName: 'bUSDT-BT',
        stakeEarnContractAddress: '0xE520105DA55563179838a0B2B4c37A5F08279f93',
        stakeEarnContractAbi: btRewardsContractABI,
        vaultsApy: "USDT V1",
        stakeApy: "busdt v1"
      },
    ]
  }
]


export const stakePools = [
  {
    id: "btu",
    name: 'BTU-BT',
    token: 'BTU',
    tokenDecimals: 18,
    valueDecimals: 10,
    tokenAddress: '0xbf6f9abc6a672fc88372aa94b34204ed9e3480ae',
    earnedToken: 'BT',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
    earnContractAddress: '0x61605159Cd5f342e39CF4E797b75985842eCa815',
    earnContractAbi: btRewardsContractABI,
    isVersion: "",
    stakeApy: "BTU"
  },
  {
    id: "btu-bt uni-v2",
    name: 'BTU-BT UNI-V2-BT',
    token: 'BTU-BT UNI-V2',
    tokenDecimals: 18,
    valueDecimals: 10,
    tokenAddress: '0x1014ee26c489e7e7e7c65dd6c84ac7c55b052687',
    earnedToken: 'BT',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
    earnContractAddress: '0xde9160B46d2850eebA3DAa8aD180D02ee25a30e6',
    earnContractAbi: btRewardsContractABI,
    isVersion: "",
    stakeApy: "BTU-BT UNI-V2"
  },
  {
    id: "beth",
    name: 'bETH-BT',
    token: 'bETH',
    tokenDecimals: 18,
    tokenAddress: '0x5C31BCe2110779f13B70B9ecB8a96073138e6A53',
    earnedToken: 'BT',
    valueDecimals: 6,
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
    earnContractAddress: '0x54e293F48b19Cb79A93648c47F048b1cD6ABf289',
    earnContractAbi: btRewardsContractABI,
    isVersion: "2",
    stakeApy: "beth"
  },
  {
    id: "bwbtc",
    name: 'bWBTC-BT',
    token: 'bWBTC',
    tokenDecimals: 8,
    tokenAddress: '0xD5fD08D2F6Db64Ad88B6083Ba2F46FE8c5E6e053',
    earnedToken: 'BT',
    valueDecimals: 4,
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
    earnContractAddress: '0xC4Cba9172D0ac518dB24F3a2391bDA94e4F37617',
    earnContractAbi: btRewardsContractABI,
    isVersion: "2",
    stakeApy: "bwbtc"
  },
   {
    id: "balUSD3CRV",
    name: 'balUSD3CRV-BT',
    token: 'balUSD3CRV',
    tokenDecimals: 18,
    tokenAddress: '0x473832D4b91a5f77BB21103631c80BDf0682EBA9',
    earnedToken: 'BT',
    valueDecimals: 10,
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
    earnContractAddress: '0x9b8DF24789C6Ae9185B85841dA5F4385DD296258',
    earnContractAbi: btRewardsContractABI,
    isVersion: "2",
    stakeApy: "balUSD3CRV"
  },
  {
    id: "buni",
    name: 'bUNI-BT v1',
    token: 'bUNI',
    tokenDecimals: 18,
    tokenAddress: '0x772E9cc4dB702789ca51B5217c2A8F748cF7f0c6',
    earnedToken: 'BT',
    valueDecimals: 10,
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
    earnContractAddress: '0xb45B9f0bC3358E878F8c9ab6ab57A8823BB590a0',
    earnContractAbi: btRewardsContractABI,
    isVersion: "1",
    stakeApy: "buni v1"
  },
  {
    id: "busdc",
    name: 'bUSDC-BT v1',
    token: 'bUSDC',
    tokenDecimals: 6,
    tokenAddress: '0x1cB255425D82FD2A49CC169aD708d860237Bd1aB',
    earnedToken: 'BT',
    valueDecimals: 4,
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
    earnContractAddress: '0x18a73A021fF130F975B13B787fE65c8c29665E83',
    earnContractAbi: btRewardsContractABI,
    isVersion: "1",
    stakeApy: "busdc v1"
  },
  {
    id: "busdt",
    name: 'bUSDT-BT v1',
    token: 'bUSDT',
    tokenDecimals: 6,
    tokenAddress: '0xaf9f57a0dCd02Db2D7c6dEd23dc3a1754cc86200',
    earnedToken: 'BT',
    valueDecimals: 4,
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
    earnContractAddress: '0xE520105DA55563179838a0B2B4c37A5F08279f93',
    earnContractAbi: btRewardsContractABI,
    isVersion: "1",
    stakeApy: "busdt v1"
  },
]

export const Bt = {
  token: 'BT',
  tokenAddress: '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
  tokenDecimals: 18,
  unit: 'USD'
}  // get Bt Balance

export const farmPools = [
  {
    id: "bt",
    name: 'BT Stake',
    description: '',
    token: 'BT',
    tokenDescription: 'BT',
    tokenDecimals: 18,
    tokenAddress: '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
    earnedToken: 'BT',
    valueDecimals: 4,
    earnedTokenDescription: '',
    earnedTokenDecimals: 8,
    earnedTokenAddress: '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
    earnContractAddress: '0x12A50C5fa45d024DbC92f852669488DC3c9c4c17',
    earnContractAbi: btAutoStakeContractABI,
    color: '52,82,128',
    startTimestamp: 1601373600,
    earnTime: 1 * 7 * 24 * 3600,
  },
  {
    id: "lp",
    name: 'BT-ETH',
    description: '',
    token: 'BT-ETH',
    tokenDescription: 'BT-ETH UNI-V2 LP',
    tokenDecimals: 18,
    tokenAddress: '0x1adac7de5c5d9894a4f6a80868ade96f8a2ed0eb',
    earnedToken: 'BT',
    valueDecimals: 4,
    earnedTokenDescription: '',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
    earnContractAddress: '0xC74d15D2e61414C0975B9DC31fA8921c9909D08D',
    earnContractAbi: btRewardsContractABI,
    color: '136,109,75',
    startTimestamp: 1601373600,
    earnTime: 2 * 7 * 24 * 3600,
  },
]

export const notice = {
  title: ''
}

export const apyConfig = 'https://api.bt.finance'

export const bscpyConfig = 'https://bscapi.bt.finance'