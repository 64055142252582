import { container } from "assets/jss/material-kit-pro-react.js";

const appStyle = theme => ({
  page: {
    backgroundColor: "#f7f7f7",
    minHeight: "100vh"
  },
  container: {
    ...container,
    zIndex: 1,
  },
  children:{
    minHeight:'77vh',
  },
  noticeWrap: {
    background: "#FF65A8",
    fontSize: "16px",
    fontFamily: "Arial",
    color: "#fff",
    padding: "24px 30px",
    lineHeight: '22px',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "10px",
  },
  notice:{
  },
  close:{
    marginRight: "0",
  },
  closeImg:{
    width: "16px",
    cursor: "pointer",
  },
});

export default appStyle;
