import React, { useEffect } from 'react';
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";

import sectionHeadStyle from "../jss/sections/sectionHeadStyle";

import { byDecimals, forMatVal } from 'features/helpers/bignumber';
import BigNumber from "bignumber.js";
import { useConnectWallet } from '../../home/redux/hooks';
import { useFetchStatistics, useFetchBalance } from '../redux/hooks';

import { Bt } from "../../configure";
const useStyles = makeStyles(sectionHeadStyle);
export default function SectionTitle(props) {

  const { web3, address } = useConnectWallet();
  const { statistics, fetchStatistics } = useFetchStatistics();
  const { balance, fetchHomeBalance } = useFetchBalance();
  const classes = useStyles();
  useEffect(() => {
    if (address) {
      fetchHomeBalance({ web3, address })
      const id = setInterval(() => {
        fetchHomeBalance({ address, web3 });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [props, fetchHomeBalance, web3, address]);

  useEffect(() => {
    fetchStatistics();
    const id = setInterval(() => {
      fetchStatistics();
    }, 10000);
    return () => clearInterval(id);
  }, [fetchStatistics]);

  const forMat = number => {
    const balance = byDecimals(number, Bt.tokenDecimals)
    return new BigNumber(
      balance
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber()
  }
  const upOrDown = (num) => {
    if (Number(num) >= 0) {
      return true
    }
    return false
  }

  const forMatValStatistics = (num) => {
    return parseFloat(forMatVal(num.toString(), 2)).toLocaleString()
  }

  const forMatValProfits = (num) => {
    if(!num) return 0
    return parseFloat(num.toFixed(2)).toLocaleString()
  }
  return (
    // <>
    //   const { TotalValueLocked, Liquidity, AnnualProfits, BFIPrice, BFIUpdown, TotalSupply, MarketCap, BFIStaked} = statistics;
    <Grid container style={{ paddingTop: '4px' }}>
      <div className={classes.chainWrap}>
        <Link className={classes.chainMain} to={'/vault'}>Ethereum</Link>
        <a className={classes.chainMinor} href={'https://bsc.bt.finance'} >BSC</a>
      </div>
      <Grid item xs={12}>
        <div className={classes.mainTitle}>Global statistics <span> (Ethereum)</span></div>
      </Grid>
      <Grid container item xs={12} spacing={3} style={{ margin: 0 }}>
        <Grid item md={4} xs={12}>
          <div className={classes.statistics}>
            <div className={classes.statisticsItem}>
              <span className={classes.itemLabel}>Total Value Locked:</span>
              <span>{forMatValStatistics(statistics.totalLockedValue || 0)} {Bt.unit}</span>
            </div>
            <div className={classes.statisticsItem}>
              <span className={classes.itemLabel}>Liquidity:</span>
              <span>{forMatValStatistics(statistics.btEthTotalValue || 0)} {Bt.unit}</span>
            </div>
            <div className={classes.statisticsItem}>
              <span className={classes.itemLabel}>Annual Profits:</span>
              <span>{forMatValProfits(Number(statistics.profits)) || 0} {Bt.unit}</span>
            </div>
          </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className={classes.balanceWrap}>
            <div className={classes.price}>
              <div className={classes.balanceLabel}>{Bt.token} Price (Uniswap):</div>
              <div className={classes.balanceTxt}>
                <span>{forMatValStatistics(statistics.btPrice || 0)} {Bt.unit} </span>
                <span className={classes.updown}>{statistics.btPriceChange || 0}% 24H</span>
                {
                  upOrDown(statistics.btPriceChange) ? (
                    <img src={require('../../../images/up_or_down.png')} alt="" className={classes.priceIcon} />
                  ) : (
                      <img src={require('../../../images/up_or_down.png')} alt="" className={classes.priceIconDown} />
                    )
                }
              </div>
            </div>
            <div className={classes.balance}>
              <div className={classes.balanceLabel}>Your Balance:</div>
              <div className={classes.balanceTxt}>{forMat(balance)} {Bt.token}</div>
            </div>
            <div className={classes.balanceBtnWrap}>
              <a
                href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.balanceBtn}>Buy BT</a>
            </div>
          </div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className={classes.statistics2}>
            <div className={classes.statisticsItem}>
              <span className={classes.itemLabel}>Circulating Supply:</span>
              <span>{forMatValStatistics(statistics.btCirculatingSupply || 0)} / {forMatValStatistics(statistics.btTotalSupply || 0)} {Bt.token}</span>
            </div>
            <div className={classes.statisticsItem}>
              <span className={classes.itemLabel}>Market Cap:</span>
              <span>{forMatValStatistics(statistics.btMarketCap || 0)} {Bt.unit}</span>
            </div>
            <div className={classes.statisticsItem}>
              <span className={classes.itemLabel}>BT Staked:</span>
              <span>{forMatValStatistics(statistics.btStakePercent || 0)}%</span>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
    //   </>
  )
}
