import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// @material-ui/core components
// @material-ui/icons
// import Close from "@material-ui/icons/Close";
// core components
// sections for this page
import StakePool  from './sections/StakePool'
// hooks

export default function PoolPage(props) {
  const history = useHistory();
  const [ index ] = useState(props.location.search.split('index=')[1] - 1);
  useEffect(() => {
    window.scrollTo(0, index*450);
    document.body.scrollTop = index*450;
  }, [history, index]);
  return (
    <>
      <StakePool {...props}/>
    </>
  );
}