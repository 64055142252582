import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  VAULT_FETCH_STAKE_BALANCE_BEGIN,
  VAULT_FETCH_STAKE_BALANCE_SUCCESS,
  VAULT_FETCH_STAKE_BALANCE_FAILURE,
} from './constants';
import { fetchStaked } from "../../web3";
import async from 'async';

export function fetchStakeBalance(data) {
  return dispatch => {
    // optionally you can have getState as the second argument
    dispatch({
      type: VAULT_FETCH_STAKE_BALANCE_BEGIN,
    });

    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      const { address, web3, stakeTokens } = data;
      const newTokens = [];
      for (let key in stakeTokens) {
        newTokens.push({
          token: key,
          stakeAddress: stakeTokens[key].stakeAddress,
          stakeAbi: stakeTokens[key].stakeAbi,
          stakeBalance: stakeTokens[key].stakeBalance,
        });
      }
      async.map(newTokens, (token, callback) => {
        async.parallel([
          (callbackInner) => { 
            fetchStaked({
              web3,
              address,
              earnContractAddress: token.stakeAddress,
              earnContractAbi:token.stakeAbi
            }).then(
              data => callbackInner(null, data)
            ).catch(
              error => {
                return callbackInner(error.message || error)
              }
            )
          }
        ], (error, data) => {
          token.stakeBalance = data[0] || 0
          callback(null, token)
        })
      }, (error, tokens) => {
        if(error) {
          console.log(error)
          dispatch({
            type: VAULT_FETCH_STAKE_BALANCE_FAILURE,
          })
          return reject(error.message || error)
        }
        const newTokens = {};
        for(let i = 0; i < tokens.length; i++) {
          newTokens[tokens[i].token] = {
            stakeAddress: tokens[i].stakeAddress,
            stakeBalance: tokens[i].stakeBalance,
            stakeAbi: tokens[i].stakeAbi
          }
        }
        dispatch({
          type: VAULT_FETCH_STAKE_BALANCE_SUCCESS,
          data: newTokens,
        })
        resolve()
      })
    });

    return promise;
  };
}

export function useFetchStakeBalance() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { stakeTokens, fetchStakeBalancePending } = useSelector(
    state => ({
      stakeTokens: state.vault.stakeTokens,
      fetchStakeBalancePending: state.vault.fetchStakeBalancePending,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchStakeBalance(data));
    },
    [dispatch],
  );

  return {
    stakeTokens,
    fetchStakeBalance: boundAction,
    fetchStakeBalancePending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_STAKE_BALANCE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchStakeBalancePending: true,
      };

    case VAULT_FETCH_STAKE_BALANCE_SUCCESS:
      // The request is success
      return {
        ...state,
        stakeTokens: action.data,
        fetchStakeBalancePending: false,
      };

    case VAULT_FETCH_STAKE_BALANCE_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchStakeBalancePending: false,
      };

    default:
      return state;
  }
}