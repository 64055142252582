import { poolsList } from "../../configure";

const tokens = {};
const stakeTokens = {};

poolsList.map( ({pools}) => {
  pools.map(({token, tokenAddress, earnedToken, earnedTokenAddress,isStake,stakeEarnContractAddress,stakeEarnContractAbi})=> {
    tokens[tokenAddress] = {
      tokenAddress: tokenAddress,
      tokenBalance: 0
    }
    tokens[earnedTokenAddress] = {
      tokenAddress: earnedTokenAddress,
      tokenBalance: 0
    }

    if(isStake === 1){
      stakeTokens[earnedTokenAddress] = {
        stakeAddress: stakeEarnContractAddress,
        stakeAbi:stakeEarnContractAbi,
        stakeBalance: 0
      }
    }
    return '';
  })
  return '';
})


const initialState = {
  poolsList,
  tokens,
  stakeTokens,
  contractApy: {},
  fetchContractApyPending: false,
  balance:0,
  statistics: {},
  allowance:{},
  fetchStatisticsPending: false,
  fetchPoolBalancesPending: false,
  fetchBalancesPending: false,
  fetchApprovalPending: {},
  fetchDepositPending: {},
  fetchWithdrawPending: {},
  fetchStakeBalancePending: false,
  vaultsList:{},
  fetchVaultsPending:false
};

export default initialState;