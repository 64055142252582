import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  VAULT_FETCH_BALANCE_BEGIN,
  VAULT_FETCH_BALANCE_SUCCESS,
  VAULT_FETCH_BALANCE_FAILURE,
} from './constants';

import { fetchBalance } from "../../web3";
import { Bt } from "../../configure";

export function fetchHomeBalance(data) {
  return (dispatch,getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: VAULT_FETCH_BALANCE_BEGIN,
    });

    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // See the real-word example at:  https://github.com/supnate/rekit/blob/master/src/features/vault/redux/fetchRedditReactjsList.js
      // args.error here is only for test coverage purpose.
      // const { address, web3 } = data;
      const { address, web3 } = data;
      fetchBalance({
        web3,
        address,
        tokenAddress: Bt.tokenAddress
      }).then(
        data => {
          dispatch({
            type: VAULT_FETCH_BALANCE_SUCCESS,
            data: data
          })
          resolve();
        }
      ).catch(
        error => {
          dispatch({
            type: VAULT_FETCH_BALANCE_FAILURE,
          })
          reject(error.message || error);
        }
      )
    });

    return promise;
  };
}

export function useFetchBalance() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { balance, fetchBalancesPending } = useSelector(
    state => ({
      balance: state.vault.balance,
      fetchBalancesPending: state.vault.fetchBalancesPending,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchHomeBalance(data));
    },
    [dispatch],
  );

  return {
    balance,
    fetchHomeBalance: boundAction,
    fetchBalancesPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_BALANCE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchBalancesPending: true,
      };

    case VAULT_FETCH_BALANCE_SUCCESS:
      // The request is success
      return {
        ...state,
        balance: action.data,
        fetchBalancesPending: false,
      };

    case VAULT_FETCH_BALANCE_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchBalancesPending: false,
      };

    default:
      return state;
  }
}