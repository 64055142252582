import initialState from './initialState';
import { reducer as fetchClaimAddressReducer } from './fetchClaimAddress';
import { reducer as fetchIsClaimReducer } from './fetchIsClaim';
import { reducer as fetchClaimReducer } from './fetchClaim';


const reducers = [
  fetchClaimAddressReducer,
  fetchIsClaimReducer,
  fetchClaimReducer,
];


export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}