
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import BigNumber from "bignumber.js";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from 'notistack';
import { toNonExponential } from 'features/helpers/bignumber';


import sectionClaimStyle from "../jss/sectionClaimStyle";

import { useConnectWallet } from '../../home/redux/hooks';
import { useFetchClaimAddress, useFetchIsClaim, useFetchClaim } from "../redux/hooks";


import { BTU } from "../../configure";

const useStyles = makeStyles(sectionClaimStyle);
export default function SectionClaim() {
  const classes = useStyles();
  const { web3, address, networkId } = useConnectWallet();
  const { claimAddress, fetchClaimAddress } = useFetchClaimAddress()
  const { isClaim, fetchIsClaim } = useFetchIsClaim()
  const { claimPending, fetchClaim } = useFetchClaim()
  useEffect(() => {
    if (address && web3) {
      fetchClaimAddress({ address });
      const id = setInterval(() => {
        fetchClaimAddress({ address });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, web3, fetchClaimAddress]);

  useEffect(() => {
    if (address && web3 && claimAddress && (claimAddress.Amount && claimAddress.Amount !== '0')) {
      fetchIsClaim({ address, web3, index: claimAddress.Index })
    }
  }, [address, web3, claimAddress, fetchIsClaim]);

  const forMatClaimNum = (number) => {
    return toNonExponential(new BigNumber(
      number
    ).dividedBy(
      new BigNumber(10).exponentiatedBy(18)
    ).toNumber())
  }
  const { enqueueSnackbar } = useSnackbar();
  const onClaim = (e) => {
    e.stopPropagation();
    if (isClaim) {
      return false
    }
    fetchClaim({
      address,
      web3,
      index: claimAddress.Index,
      amount: claimAddress.Amount,
      merkleProof: claimAddress.Proof,
    }).then(
      () => enqueueSnackbar(`Claim success`, { variant: 'success' })
    ).catch(
      error => enqueueSnackbar(`Claim error: ${error}`, { variant: 'error' })
    )
  }
  return (
    <div className={classes.claimWrap}>
      <div className={classes.claimTitle}>BTU (BT.Finance IOU Token) claim</div>
      <div className={classes.claimText}>
        The distribution of BTU is based on snapshots of ETH, USDC and USDT vaults before the hacking. ETH is calculated at $1800.
        Staking BTU and BTU/BT UNI-V2 can get a total of 15000 tokens compensation in the next 1 year, you can also trade with
        <a className={classes.claimLink} rel="noopener noreferrer" href={'https://info.uniswap.org/pair/0x1014ee26c489e7e7e7c65dd6c84ac7c55b052687'} target="_blank">
          {' BTU/BT pair '}
        </a> on uniswap freely.
      </div>
      <div className={classes.claimImgWrap}>
        <img className={classes.claimImg} alt="" src={require(`../../../images/claim.png`)} />
      </div>
      <div className={classes.userClaim}>
        <div className={classes.userClaimTxt}>Your claim</div>
        {
          claimAddress && claimAddress.Amount !== '0' && !isClaim ? (
            <div className={classes.userClaimNum}>{forMatClaimNum(claimAddress?.Amount)} BTU</div>
          ) : (
              <div className={classes.userNoClaimTxt}>User has no claim</div>)
        }
        <div className={classes.userClaimBtnWrap}>
          {/* <div className={classes.userClaimBtn}>Connect Wallet</div> */}
          {
            address && (<Button
              onFocus={(event) => event.stopPropagation()}
              disabled={isClaim || claimPending || claimAddress.Amount === '0'}
              onClick={onClaim.bind(this)}
            >Claim BTU
            </Button>)
          }
        </div>
      </div>

      <div className={classes.claimToken}>
        <div>{`MerkleDistributor: ${BTU.merkleRoot}`}</div>
        <div>{`BTU token: ${BTU.token}`}</div>
      </div>
    </div>
  )
}