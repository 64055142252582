/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import { renderIcon } from '@download/blockies'
// react components for routing our app without refresh
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Avatar from '@material-ui/core/Avatar';
// @material-ui/icons
import TranslateIcon from '@material-ui/icons/Translate';
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
// hooks
import { useTranslation } from 'react-i18next';

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import { headerTab } from "../../features/configure";
const useStyles = makeStyles(styles);

{/* <Tabs className={classes.tabs} value={tabValue} onChange={changeTabs}>
<Tab value='' label='Home' id='bar-tab-0'/>
<Tab value='vault' label='Vault' id='bar-tab-1'/>
<Tab value='stake' label='Stake' id='bar-tab-2'/>
</Tabs> */}


export default function HeaderLinks(props) {
  let history = useHistory();
  const { dropdownHoverColor, connected, address, connectWallet, disconnectWallet } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [lng, setLanguage] = useState('en');
  const [shortAddress, setShortAddress] = useState('');
  const [dataUrl, setDataUrl] = useState(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    if (!connected) return;
    const canvas = canvasRef.current
    renderIcon({ seed: address.toLowerCase() }, canvas)
    const updatedDataUrl = canvas.toDataURL()
    if (updatedDataUrl !== dataUrl) {
      setDataUrl(updatedDataUrl)
    }
    if (address.length < 11) {
      setShortAddress(address)
    } else {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`)
    }
  }, [dataUrl, address])

  const switchLanguage = () => {
    switch (i18n.language) {
      case 'zh':
      case 'zh-CN':
        return '中文'
      case 'en':
        return 'English'
      case 'ja':
        return '日本語'
      case 'th':
        return 'ไทย'
      case 'ko':
        return '한글'
      default:
        return '中文'
    }
  }

  const handleClick = event => {
    switch (event) {
      case 'English':
        return i18n.changeLanguage('en').then(() => setLanguage(event))
      case '中文':
        return i18n.changeLanguage('zh').then(() => setLanguage(event))
      case '日本語':
        return i18n.changeLanguage('ja').then(() => setLanguage(event))
      case 'ไทย':
        return i18n.changeLanguage('th').then(() => setLanguage(event))
      case '한글':
        return i18n.changeLanguage('ko').then(() => setLanguage(event))
      default:
        return
    }
  }

  const changeTabs = (newValue) => {
    history.push({
      pathname: '/' + newValue,
      state: {
      }
    })
  }

  useEffect(() => {
    const lng = switchLanguage()
    setLanguage(lng);
  });

  let defaultTabValue = '';
  if (window.location.pathname != '/' && window.location.pathname != '/index') {
    defaultTabValue = window.location.pathname.split('/')[1];
  }

  return (
    <>
      <List className={classes.list}>
        {
          headerTab.map((item, index) => {
            const { link } = item
            return (
              <ListItem key={'tab-' + index} className={classes.listItem}>
                {
                  !Boolean(link) ? (
                    <div onClick={changeTabs.bind(this, item.value)}
                      className={item.value == defaultTabValue ? classes.itemLinkActive : classes.itemLink}>{item.title}</div>
                  ) : (
                    <a href={item.link} target="_blank" className={classes.itemLink}>{item.title}</a>
                  )
                }
              </ListItem>
            )
          })
        }
      </List>

      <Button
        style={{
          width: '180px',
          margin: '9px 0',
          padding: '6px 0',
          fontSize: '14px',
          fontWeight: 'bold',
          backgroundColor: '#4A4FE2',
          color: '#fff',
          boxShadow: '0 2px 2px 0 rgba(53, 56, 72, 0.14), 0 3px 1px -2px rgba(53, 56, 72, 0.2), 0 1px 5px 0 rgba(53, 56, 72, 0.12)',
        }}
        className={classes.Button}
        round
        type="button"
        color="primary"
        onClick={connected ? disconnectWallet : connectWallet}
      >
        {connected ? (
          <>
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <Avatar alt="address" src={dataUrl} style={{
              width: '24px',
              height: "24px",
              marginRight: '10px',
            }} />{shortAddress}
          </>
        ) : (
          <>
            Connect your wallet
            </>
        )}
      </Button>
    </>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
