import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
//  notistack
import { SnackbarProvider } from 'notistack';
import Button from "@material-ui/core/Button";
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Drawer from "@material-ui/core/Drawer";
import Close from "@material-ui/icons/Close";
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from "@material-ui/icons/Menu";
import GridContainer from "components/Grid/GridContainer.js";


import { makeStyles } from "@material-ui/core/styles";


import homeStyle from "./jss/homeStyle.js";
import { homeTab, homeFooter, Bt } from "../configure";
import { forMatVal } from 'features/helpers/bignumber';


import { useFetchStatistics } from '../vault/redux/hooks';
import { useFetchBscStatistics } from './redux/hooks';

const useStyles = makeStyles(homeStyle);
export default function Home({ children }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();

  let defaultTabValue = '';
  if (window.location.pathname !== '/' && window.location.pathname !== '/index') {
    defaultTabValue = window.location.pathname.split('/')[1];
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const { statistics, fetchStatistics } = useFetchStatistics();
  const { bscStatistics, fetchBscStatistics } = useFetchBscStatistics();

  useEffect(() => {
    fetchStatistics();
    fetchBscStatistics();
  }, [fetchBscStatistics, fetchStatistics]);

  const forMatValStatistics = (num) => {
    return parseFloat(forMatVal(num.toString(), 2)).toLocaleString()
  }

  const forMatMun = (num1, num2) => {
    return forMatValStatistics(Number(num1) + Number(num2))
  }

  const forMatValProfits = (num) => {
    if (!num) return 0
    return parseFloat(num.toFixed(2)).toLocaleString()
  }


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <SnackbarProvider>
      <div className={classes.headerWrap}>

        <AppBar className={classes.headerWrapBar}>
          <Toolbar className={classes.header}>
            <Button className={classes.title}>
              <Avatar alt="Best DeFi yield aggregator for Tokens" src={require(`../../images/BT-logo.png`)} />
              <Link to="/">
                <div className={classes.titleBrand}>BT.Finance</div>
                <div className={classes.titleTxt}>Best yield for Tokens！</div>
              </Link>
            </Button>
            <Hidden smDown implementation="css" className={classes.headerBarHidden}>
              <div className={classes.headerBarLink}>
                <List className={classes.list}>
                  {
                    homeTab.map((item, index) => {
                      const { link } = item
                      return (
                        <ListItem key={'tab-' + index} className={classes.listItem}>
                          {
                            !Boolean(link) ? (
                              <div
                                className={item.value === defaultTabValue ? classes.itemLinkActive : classes.itemLink}>{item.title}</div>
                            ) : (
                              <a href={item.link} target="_blank" rel="noopener noreferrer" className={classes.itemLink}>{item.title}</a>
                            )
                          }
                        </ListItem>
                      )
                    })
                  }
                  <div>
                    <div className={classes.itemLinkBtn} aria-controls="scurity-menu" aria-haspopup="true" onClick={(e) => handleClick(e)}>
                      Security
                   </div>
                    <Menu
                      id="scurity-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem >
                        <a
                          href={'https://github.com/btdotfinance/bt-finance/tree/master/audits'}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.itemLinkRow}
                          onClose={handleClose}> Audits</a>
                      </MenuItem>
                      <MenuItem >
                        <a
                          href={'https://immunefi.com/bounty/btfinance/'}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.itemLinkRow}
                          onClose={handleClose}>Bounty</a>
                      </MenuItem>
                      <MenuItem >
                        <a
                          href={'https://app.coverprotocol.com/app/marketplace/protocols/BTFINANCE'}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.itemLinkRow}
                          onClose={handleClose}> Cover</a>
                      </MenuItem>
                    </Menu>
                  </div>

                  <ListItem key={'tab-github'} className={classes.listItem}>
                    <a href={'https://github.com/btdotfinance'} target="_blank" rel="noopener noreferrer" className={classes.itemLink}>Github</a>
                  </ListItem>
                </List>


              </div>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>

          <Hidden mdUp implementation="js">
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={mobileOpen}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={handleDrawerToggle}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                className={classes.closeButtonDrawer}
              >
                <Close />
              </IconButton>
              <div className={classes.appResponsive}>
                <List className={classes.list}>
                  {
                    homeTab.map((item, index) => {
                      const { link } = item
                      return (
                        <ListItem key={'tab-' + index} className={classes.listItem}>
                          {
                            !Boolean(link) ? (
                              <div
                                className={item.value === defaultTabValue ? classes.itemLinkActive : classes.itemLink}>{item.title}</div>
                            ) : (
                              <a href={item.link} target="_blank" rel="noopener noreferrer" className={classes.itemLink}>{item.title}</a>
                            )
                          }
                        </ListItem>
                      )
                    })
                  }
                  <ListItem key={'tab-audits'} className={classes.listItem}>
                    <a href={'https://github.com/btdotfinance/bt-finance/tree/master/audits'} target="_blank" rel="noopener noreferrer" className={classes.itemLink}>Audits</a>
                  </ListItem>
                  <ListItem key={'tab-bounty'} className={classes.listItem}>
                    <a href={'https://immunefi.com/bounty/btfinance/'} target="_blank" rel="noopener noreferrer" className={classes.itemLink}>Bounty</a>
                  </ListItem>
                  <ListItem key={'tab-cover'} className={classes.listItem}>
                    <a href={'https://app.coverprotocol.com/app/marketplace/protocols/BTFINANCE'} target="_blank" rel="noopener noreferrer" className={classes.itemLink}>Cover</a>
                  </ListItem>
                  <ListItem key={'tab-github'} className={classes.listItem}>
                    <a href={'https://github.com/btdotfinance'} target="_blank" rel="noopener noreferrer" className={classes.itemLink}>Github</a>
                  </ListItem>
                </List>
              </div>
            </Drawer>
          </Hidden>
        </AppBar>
        <div className={classes.headerContainer}>
          <Grid container justify="space-around" spacing={2} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <Grid item md={6} xs={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <div className={classes.headerContainerTitle}>BT.Finance -Multi-chain smart DeFi yield aggregator</div>
              <div className={classes.headerContainerText}>
                BT.Finance is a multi-chain smart DeFi yield aggregator targets the best and sustainable yield
              </div>
              <div className={classes.headerBtn}>
                <Link className={classes.btnMain} to="/vault">Go to Ethereum</Link>
                <a className={classes.btnMionr} href={'https://bsc.bt.finance'}>Go to BSC</a>
              </div>
            </Grid>
            <Grid item md={6} xs={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <img src={require(`../../assets/img/head.png`)} alt="" className={classes.headImg} />
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={classes.conWrap}>
        <div className={classes.conContainer}>
          <Grid container justify="space-around" spacing={2} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <Grid item md={4} xs={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <div className={classes.conText}>{forMatMun(statistics.totalLockedValue ?? 0, bscStatistics.totalLockedValue ?? 0)} {Bt.unit}</div>
              <div className={classes.conlabel}>Total Value Locked</div>
            </Grid>
            <Grid item md={4} xs={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <div className={classes.conText}>{forMatMun(statistics.btEthTotalValue ?? 0, bscStatistics.btEthTotalValue ?? 0)} {Bt.unit}</div>
              <div className={classes.conlabel}>Liquidity</div>
            </Grid>
            <Grid item md={4} xs={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <div className={classes.conText}>{forMatValProfits(Number(statistics.profits) + Number(bscStatistics.profits)) || 0} {Bt.unit}</div>
              <div className={classes.conlabel}>Annual Profits</div>
            </Grid>
          </Grid>
          <Grid container justify="space-around" spacing={2} style={{ paddingTop: "0px", paddingBottom: "0px", marginTop: "80px" }}>
            <Grid item md={6} xs={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <div className={classes.conText}>{forMatValStatistics(statistics.btCirculatingSupply || 0)} / {forMatValStatistics(statistics.btTotalSupply || 0)} {Bt.token}</div>
              <div className={classes.conlabel}>Circulating Supply</div>
            </Grid>
            <Grid item md={6} xs={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <div className={classes.conText}>{forMatValStatistics(statistics.btMarketCap || 0)} {Bt.unit}</div>
              <div className={classes.conlabel}>Market Cap</div>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={classes.footerWrap}>
        <div className={classes.footerContainer}>
          <div className={classes.footerTitle}>A growing Yield ecosystem</div>
          <div className={classes.footerText}>BT.Finance balances security and profitability from the DeFi ecosystem to bring the best sustainable benefits to users with different risk preferences.</div>
          <div className={classes.footerImg}>
            <img src={require(`../../assets/img/footer.png`)} alt="" className={classes.footerImgIcon} />
          </div>
        </div>
      </div>


      <div className={classes.footerLinks}>
        <div className={classes.footerLinksContainer}>
          <div className={classes.copyright}>©2020 BT.Finance</div>
          <GridContainer className={classes.linkList} justify='center' align='center' >
            {
              homeFooter.map((item) => {
                return (
                    <a key={item.title} className={classes.linkItem} href={item.link} target="_blank" rel="noopener noreferrer">
                      <img src={item.img} alt='' style={{ width: "26px" }} />
                    </a>
                )
              })
            }
          </GridContainer>
        </div>
      </div>

    </SnackbarProvider>
  );
}
