import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { byDecimals, calculateReallyNum, forMatVal,toNonExponential } from 'features/helpers/bignumber';
import { useHistory } from 'react-router-dom';

import BigNumber from "bignumber.js";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Avatar } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import CustomSlider from 'components/CustomSlider/CustomSlider';
import Button from "components/CustomButtons/Button.js";

import { useConnectWallet } from '../../home/redux/hooks';
import {
  useFetchPoolsInfo, useFetchBalance, useFetchCurrentlyStaked, useFetchRewardsAvailable,
  useFetchStake, useFetchWithdraw, useFetchClaim, useFetchExit, useFetchApproval, useFetchStakeBalances
} from '../../farm/redux/hooks';
import { useFetchContractApy } from '../redux/hooks';

import stakePoolStyles from "../jss/sections/stakePoolStyles";

import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';

const useStyles = makeStyles(stakePoolStyles);

export default function StakePool() {

  const { web3, address } = useConnectWallet();

  const { pools, fetchPoolsInfo } = useFetchPoolsInfo();
  const { tokens, fetchBalances } = useFetchBalance();
  const { tokensStake, fetchStakeBalances } = useFetchStakeBalances();
  const classes = useStyles();
  const { rewardsAvailable, fetchRewardsAvailable } = useFetchRewardsAvailable();
  const { currentlyStaked, fetchCurrentlyStaked } = useFetchCurrentlyStaked();

  const { fetchApproval } = useFetchApproval();
  const { fetchStake } = useFetchStake();
  const { fetchWithdraw } = useFetchWithdraw();
  const { fetchClaim } = useFetchClaim();
  const { fetchExit } = useFetchExit();
  const { contractApy, fetchContractApy } = useFetchContractApy();

  const [stakeBalance, setStakeBalance] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState({});

  const changeDetailInputValue = (type, index, total, tokenDecimals,dec, event) => {
    const num = forMat(total,dec)
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    let sliderNum = 0;
    let inputVal = Number(forMatVal(value.toString(),dec).replace(',', ''));
    console.log(inputVal);
    if (value) {
      sliderNum = byDecimals(inputVal / num, 0).toFormat(2) * 100;
    }
    switch (type) {
      case 'stakeBalance':
        setStakeBalance({
          ...stakeBalance,
          [index]: inputFinalVal(value, num, tokenDecimals,dec),
          [`slider-${index}`]: sliderNum,
        });
        break;
      case 'withdrawAmount':
        setWithdrawAmount({
          ...withdrawAmount,
          [index]: inputFinalVal(value, num, tokenDecimals,dec),
          [`slider-${index}`]: sliderNum,
        });
        break;
      default:
        break;
    }
  }
  const handleDepositedBalance = (index, total,dec, event, sliderNum) => {
    const num = forMat(total,dec)
    setStakeBalance({
      ...stakeBalance,
      [index]: sliderNum === 0 ? 0 : calculateReallyNum(num, sliderNum,dec),
      [`slider-${index}`]: sliderNum === 0 ? 0 : sliderNum,
    });
  }
  const handleWithdrawAmount = (index, total,dec, event, sliderNum) => {
    const num = forMat(total,dec)
    setWithdrawAmount({
      ...withdrawAmount,
      [index]: sliderNum === 0 ? 0 : calculateReallyNum(num, sliderNum,dec),
      [`slider-${index}`]: sliderNum === 0 ? 0 : sliderNum,
    });
  };


  const onStake = (pool, index, event) => {
    event.stopPropagation();
    let amountValue = stakeBalance[index] ? stakeBalance[index].toString().replace(',', '') : stakeBalance[index];
    if (amountValue === undefined) {
      amountValue = '0';
    }
    const amount = new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10)
    if (Number(amount) > 0) {
      if (Boolean(pool.allowance === 0)) {
        fetchApproval({ address, web3, pool, index }).then(() => {
          fetchStake({ address, web3, pool, amount, index })
        })
      } else {
        fetchStake({ address, web3, pool, amount, index })
      }
    }
  }
  const onUnstake = (pool, index, stakeSingle, event) => {
    event.stopPropagation();
    if (Boolean(forMat(stakeSingle,pool.valueDecimals)) && Boolean(withdrawAmount[index])) {
      let amountValue = withdrawAmount[index] ? withdrawAmount[index].toString().replace(',', '') : withdrawAmount[index];
      if (amountValue === undefined) {
        amountValue = '0';
      }
      const amount = new BigNumber(amountValue).multipliedBy(new BigNumber(10).exponentiatedBy(pool.tokenDecimals)).toString(10)
      fetchWithdraw({ address, web3, pool, amount, index })
    }

  }
  const onClaimRewards = (pool, index, rewardsSingle, event) => {
    event.stopPropagation();
    if (Boolean(forMat(rewardsSingle,pool.valueDecimals))) {
      fetchClaim({ address, web3, pool, index })
    }
  }
  const onExit = (pool, index, stakeSingle, event) => {
    event.stopPropagation();
    if (Boolean(forMat(stakeSingle,pool.valueDecimals))) {
      fetchExit({ address, web3, pool, index })
    }
  }
  const onAutoExit = (pool, index, stakeSingle, event) => {
    event.stopPropagation();
    if (Boolean(forMat(stakeSingle,pool.valueDecimals))) {
      fetchExit({ address, web3, pool, index })
    }
  }


  useEffect(() => {
    if (address && web3) {
      fetchBalances({ address, web3 });
      fetchStakeBalances({ address, web3 });
      fetchRewardsAvailable({ address, web3 });
      fetchCurrentlyStaked({ address, web3 });
      fetchPoolsInfo({ address, web3 });
      const id = setInterval(() => {
        fetchBalances({ address, web3 });
        fetchStakeBalances({ address, web3 });
        fetchRewardsAvailable({ address, web3 });
        fetchCurrentlyStaked({ address, web3 });
        fetchPoolsInfo({ address, web3 });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, web3, fetchBalances, fetchPoolsInfo, fetchRewardsAvailable, fetchCurrentlyStaked, fetchStakeBalances]);

  const forMat = (number,dec = 4) => {
    return toNonExponential(new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10).exponentiatedBy(dec)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10).exponentiatedBy(dec)
    ).toNumber())
  }
  useEffect(() => {
    fetchContractApy();
    const id = setInterval(() => {
      fetchContractApy();
    }, 10000);
    return () => clearInterval(id);
  }, [fetchContractApy]);

  const history = useHistory();
  const handleBack = (event) => {
    event.stopPropagation();
    history.goBack()
  }
  const offsetImageStyle = { marginLeft: "-30px", zIndex: 0, background: '#ffffff' }
  return (
    <Grid container style={{ paddingTop: '4px' }}>
      <div className={classes.mainTitle}>
        <Hidden xsDown>
          <div className={classes.back} onClick={handleBack.bind(this)}>Back</div>
        </Hidden>
        <div className={classes.title}>Stake your BT or LP</div>
      </div>
      <Grid container item xs={12} justify={"center"}>
        {
          pools.map((pool, index) => {
            const { token, name, color, id } = pool;
            // get LP
            const isLP = id.toLowerCase().indexOf('lp') > -1;
            const lpTokens = isLP ? token.split('-') : [];

            let balanceSingle = byDecimals(tokens[pool.token].tokenBalance, pool.tokenDecimals);
            let rewardsSingle = byDecimals(rewardsAvailable[pool.name].tokenRewards, pool.earnedTokenDecimals);
            let stakeSingle = byDecimals(currentlyStaked[pool.token].tokenStaked, pool.tokenDecimals);
            let stakeBalanceSingle = byDecimals(tokensStake[pool.token].balance, pool.tokenDecimals);
            let depositedApy = `${contractApy[pool.token] || 0}%`;
            return (
              <Grid item sm={12} key={index}>
                {isLP && lpTokens.length === 2 ? (
                  <div style={{ background: `rgba(${color},1)` }} className={classes.stakeWrap}>
                    <div className={classes.stakeContainer}>
                      <div className={classes.logo}>
                        {
                          lpTokens.map((item, keyIndex) => {
                            return (
                              <Avatar key={keyIndex}
                                src={require(`../../../images/${item}-logo.png`)} className={classes.logoImage}
                                style={keyIndex > 0 ? offsetImageStyle : {}}
                              />
                            )
                          })
                        }
                      </div>
                      <div className={classes.name}>{name}</div>
                      <Grid container item xs={12} justify={"center"}>
                        <Grid item xs={6} md={3}>
                          <Grid item>
                            <Typography className={classes.containerMainTitle} variant="body2" gutterBottom noWrap> {depositedApy}</Typography>
                            <Typography className={classes.containerSubTitle} variant="body2">Total APY</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Grid item>
                            <Typography className={classes.containerMainTitle} variant="body2" gutterBottom noWrap>{forMat(rewardsSingle,pool.valueDecimals)} {pool.earnedToken}</Typography>
                            <Typography className={classes.containerSubTitle} variant="body2">Earned</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Grid item>
                            <Typography className={classes.containerMainTitle} variant="body2" gutterBottom noWrap>{forMat(balanceSingle,pool.valueDecimals)} {pool.token}</Typography>
                            <Typography className={classes.containerSubTitle} variant="body2">Balance</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Grid item>
                            <Typography className={classes.containerMainTitle} variant="body2" gutterBottom noWrap>{forMat(stakeSingle,pool.valueDecimals)} {pool.token}</Typography>
                            <Typography className={classes.containerSubTitle} variant="body2">Staked</Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container item xs={12} justify={"center"}>
                        <Grid item xs={12} sm={6} className={classes.sliderDetailContainer}>
                          <div className={classes.showDetailRight}>
                            balance:{forMat(balanceSingle,pool.valueDecimals)} {pool.token}
                          </div>
                          <FormControl fullWidth variant="outlined">
                            <div className={classes.stakeInput} style={{ background: "rgba(255, 255, 255, 0.2)" }}>
                              <CustomOutlinedInput
                                value={stakeBalance[index] !== undefined ? stakeBalance[index] : '0'}
                                onChange={changeDetailInputValue.bind(this, 'stakeBalance', index, balanceSingle, pool.tokenDecimals,pool.valueDecimals)}
                              />
                            </div>
                          </FormControl>
                          <div className={classes.sliderWrap}>
                            <CustomSlider
                              classes={{
                                root: classes.depositedBalanceSliderRoot,
                                rail: classes.depositedBalanceSliderRail,
                                markLabel: classes.depositedBalanceSliderMarkLabel,
                              }}
                              aria-labelledby="continuous-slider"
                              value={stakeBalance['slider-' + index] ? stakeBalance['slider-' + index] : 0}
                              onChange={handleDepositedBalance.bind(this, index, balanceSingle,pool.valueDecimals)}
                            />
                          </div>
                          <div className={classes.showDetailButtonCon}>
                            <Button
                              onFocus={(event) => event.stopPropagation()}
                              onClick={onStake.bind(this, pool, index)}
                              className={classes.staleBtn}
                              style={{ color: `#FEA42E` }}
                            >Stake </Button>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.sliderDetailContainer}>
                          <div className={classes.showDetailRight}>
                            Staked: {forMat(stakeSingle,pool.valueDecimals)} {pool.token}
                          </div>
                          <FormControl fullWidth variant="outlined">
                            <div className={classes.stakeInput} style={{ background: "rgba(255, 255, 255, 0.2)" }}>
                              <CustomOutlinedInput
                                value={withdrawAmount[index] !== undefined ? withdrawAmount[index] : '0'}
                                onChange={changeDetailInputValue.bind(this, 'withdrawAmount', index, stakeSingle.toNumber(), pool.tokenDecimals,pool.valueDecimals)}
                              />
                            </div>
                          </FormControl>
                          <div className={classes.sliderWrap}>
                            <CustomSlider
                              classes={{
                                root: classes.depositedBalanceSliderRoot,
                                rail: classes.depositedBalanceSliderRail,
                                markLabel: classes.depositedBalanceSliderMarkLabel,
                              }}
                              aria-labelledby="continuous-slider"
                              value={withdrawAmount['slider-' + index] ? withdrawAmount['slider-' + index] : 0}
                              onChange={handleWithdrawAmount.bind(this, index, stakeSingle.toNumber(),pool.valueDecimals)}
                            />
                          </div>

                          <div className={classes.showDetailButtonCon}>
                            <Button
                              onFocus={(event) => event.stopPropagation()}
                              onClick={onUnstake.bind(this, pool, index, stakeSingle.toNumber())}
                              className={classes.staleBtn}
                              style={{ color: `#FEA42E` }}>Unstake </Button>
                          </div>
                        </Grid>

                        <Grid xs={12} item container style={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
                          <div className={classes.showDetailOrtherButtonCon}>
                            <Button
                              onFocus={(event) => event.stopPropagation()}
                              className={`${classes.detailsOrtherBtn} ${classes.staleBtn}`}
                              onClick={onClaimRewards.bind(this, pool, index, rewardsSingle)}
                              style={{ color: `#FEA42E` }}>
                              Claim Rewards
                      </Button>
                          </div>
                        </Grid>
                        <Grid xs={12} item container style={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
                          <div className={classes.showDetailOrtherButtonCon}>
                            {/* disabled={
                          !Boolean(stakeBalance[index]) || (new BigNumber(stakeBalance[index]).toNumber() > 0)
                        } */}
                            <Button
                              onFocus={(event) => event.stopPropagation()}
                              className={`${classes.detailsOrtherBtn} ${classes.staleBtn}`}
                              onClick={onExit.bind(this, pool, index, stakeSingle)}
                              style={{ color: `#FEA42E` }}>
                              Exit:Claim And Unstake
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                ) : (
                    <div style={{ background: `rgba(${color},1)` }} className={classes.stakeWrap}>
                      <div className={classes.stakeContainer}>
                        <div className={classes.logo}>
                          <Avatar src={require(`../../../images/${token}-logo.png`)} className={classes.logoImage} />
                        </div>
                        <div className={classes.name}>{name}</div>
                        <Grid container item xs={12} justify={"center"}>
                          <Grid item xs={6} md={3}>
                            <Grid item>
                              <Typography className={classes.containerMainTitle} variant="body2" gutterBottom noWrap> {depositedApy}</Typography>
                              <Typography className={classes.containerSubTitle} variant="body2">Total APY</Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <Grid item>
                              <Typography className={classes.containerMainTitle} variant="body2" gutterBottom noWrap>Auto-Compounding</Typography>
                              <Typography className={classes.containerSubTitle} variant="body2">Earned</Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <Grid item>
                              <Typography className={classes.containerMainTitle} variant="body2" gutterBottom noWrap>{forMat(balanceSingle,pool.valueDecimals)} {pool.token}</Typography>
                              <Typography className={classes.containerSubTitle} variant="body2">Unstaked</Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <Grid item>
                              <Typography className={classes.containerMainTitle} variant="body2" gutterBottom noWrap>
                                {
                                  forMat(stakeBalanceSingle,pool.valueDecimals) > 0 ? `${forMat(stakeBalanceSingle,pool.valueDecimals)} ${pool.token}` : 'Auto-Compounding'
                                }
                              </Typography>
                              <Typography className={classes.containerSubTitle} variant="body2">Staked</Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container item xs={12} justify={"center"}>
                          <div className={classes.showDetailRight}>
                            balance:{forMat(balanceSingle,pool.valueDecimals)} {pool.token}
                          </div>
                          <FormControl fullWidth variant="outlined">
                            <div className={classes.stakeInput} style={{ background: "rgba(255, 255, 255, 0.2)" }}>
                              <CustomOutlinedInput
                                value={stakeBalance[index] !== undefined ? stakeBalance[index] : '0'}
                                onChange={changeDetailInputValue.bind(this, 'stakeBalance', index, balanceSingle, pool.tokenDecimals,pool.valueDecimals)}
                              />
                            </div>
                          </FormControl>
                          <div className={classes.sliderWrap}>
                            <CustomSlider
                              classes={{
                                root: classes.depositedBalanceSliderRoot,
                                rail: classes.depositedBalanceSliderRail,
                                markLabel: classes.depositedBalanceSliderMarkLabel,
                              }}
                              aria-labelledby="continuous-slider"
                              value={stakeBalance['slider-' + index] ? stakeBalance['slider-' + index] : 0}
                              onChange={handleDepositedBalance.bind(this, index, balanceSingle,pool.valueDecimals)}
                            />
                          </div>
                          <div className={classes.showDetailButtonCon}>
                            <Button onFocus={(event) => event.stopPropagation()}
                              onClick={onStake.bind(this, pool, index)}
                              className={classes.staleBtn}
                              style={{ color: `#5993EB` }}>Stake </Button>
                            <Button onFocus={(event) => event.stopPropagation()}
                              onClick={onAutoExit.bind(this, pool, index, stakeSingle)}
                              className={classes.staleBtn}
                              style={{ color: `#5993EB` }}>Exit </Button>
                          </div>
                        </Grid>
                      </div>
                    </div>
                  )}
              </Grid>
            )
          })
        }
      </Grid>
    </Grid>
  )
}
