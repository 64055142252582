import { farmPools } from "../../configure";


const tokens = {}
const rewardsAvailable = {}
const currentlyStaked = {}
const tokensStake = {}

farmPools.map(({ token,name,id, tokenAddress, earnContractAddress, earnContractAbi }) => {
	tokens[token] = {
		tokenAddress: tokenAddress,
		tokenBalance: 0
	}
	tokensStake[token] = {
		earnContractAddress: earnContractAddress,
		balance: 0,
		id:id
	}
	rewardsAvailable[name] = {
		earnContractAddress: earnContractAddress,
		earnContractAbi: earnContractAbi,
		tokenRewards: 0
	}
	currentlyStaked[token] = {
		earnContractAddress: earnContractAddress,
		earnContractAbi: earnContractAbi,
		tokenStaked: 0
	}
	return '';
})


const initialState = {
  pools:farmPools,
	tokens,
	tokensStake,
  rewardsAvailable,
	currentlyStaked,
	allowance:{},
  fetchBalancePending:{},
  fetchRewardsAvailablePending:{},
  fetchCurrentlyStakedPending:{},
  fetchApprovalPending:{},
  fetchStakePending:{},
  fetchClaimPending:{},
	fetchExitPending:{},
  fetchStakeBalancePending:{},
	checkApprovalPending:{}
};

export default initialState;