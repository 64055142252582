import { container, defaultFont } from "assets/jss/material-kit-pro-react.js";
import {
  grayColor,
  changeColor,
  drawerWidth,
  transition
} from "assets/jss/material-kit-pro-react.js";
const appStyle = theme => ({
  headerWrap: {
    backgroundColor: "#F7F7FD",
  },
  headerWrapBar:{
    backgroundColor: "#F7F7FD",
    boxShadow: "none",
    color: "#2D2E35"
  },
  header: {
    ...container,
    minHeight: "60px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
  },
  title: {
    letterSpacing: "unset",
    "&,& a": {
      ...defaultFont,
      minWidth: "unset",
      lineHeight: "28px",
      padding: "0 4px",
      fontSize: "24px",
      fontWeight: "700",
      borderRadius: "3px",
      textTransform: "none",
      whiteSpace: "nowrap",
      color: "inherit",
      "&:hover,&:focus": {
        color: "inherit",
        background: "transparent"
      }
    },
    "& a": {
      marginLeft: "8px",
    }
  },
  titleBrand: {
    textAlign: "left",
  },
  titleTxt: {
    textAlign: "left",
    fontSize: "14px",
    fontFamily: "Arial",
    fontWeight: "400",
    lineHeight: "16px",
    color: '#87878B',
  },
  nav: {
    fontSize: "14px",
    color: '#2D2E35',
  },
  headerContainer: {
    ...container,
    zIndex: 1,
    padding: "40px 0",
    marginTop: "60px"
  },
  headerContainerTitle: {
    fontSize: "44px",
    color: '#2D2E35',
    fontWeight: 500,
    lineHeight: "60px",
    margin: "60px 0 40px"
  },
  headerContainerText: {
    fontSize: "24px",
    color: '#2D2E35',
    fontWeight: 400,
    lineHeight: "28px",
  },
  headImg: {
    maxWidth: "550px",
    width:"100%"
  },
  headerBtn: {
    display: "flex",
    marginTop: "60px",
  },
  btnMionr: {
    width: "200px",
    height: "52px",
    lineHeight: "52px",
    textAlign: 'center',
    fontSize: "16px",
    color: '#4A4FE2',
    background: "#E6E6FF",
    borderRadius: "26px",
    "&:hover,&:focus": {
      color: "#4A4FE2",
    }
  },
  btnMain: {
    width: "200px",
    height: "52px",
    lineHeight: "52px",
    textAlign: 'center',
    fontSize: "16px",
    color: '#ffffff',
    background: "#4A4FE2",
    marginRight: "24px",
    borderRadius: "26px",
    "&:hover,&:focus": {
      color: "#ffffff",
    }
  },
  conWrap: {
    background: "#ffffff",
  },
  conContainer: {
    ...container,
    zIndex: 1,
    padding: "100px 0"
  },
  footerWrap: {
    backgroundColor: "#F7F7FD",
  },
  footerContainer: {
    ...container,
    zIndex: 1,
    padding: "86px 0 36px",
  },
  footerTitle: {
    fontSize: "32px",
    color: '#2D2E35',
    fontWeight: 500,
    lineHeight: "60px",
    textAlign: 'center',
  },
  footerText: {
    maxWidth: "750px",
    margin: "0 auto",
    fontSize: "18px",
    color: '#2D2E35',
    fontWeight: 400,
    lineHeight: "30px",
    textAlign: 'center',
  },
  footerImg: {
    maxWidth: "1160px",
    margin: "0 auto",
  },
  footerImgIcon: {
    width: "100%",
  },
  conText:{
    fontSize: "36px",
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: "46px",
  },
  conlabel:{
    fontSize: "16px",
    textAlign: 'center',
    margin: "16px 0",
  },
  headerBarHidden: {
  },
  headerBarLink: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap"
  },
  list: {
    [theme.breakpoints.up("md")]: {
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      MsFlexDirection: "row",
      flexDirection: "row"
    },
    [theme.breakpoints.down("sm")]: {
      display: "block"
    },
    marginTop: "0px",
    display: "flex",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
    padding: "0"
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll"
      },
      width: "100%",
      "&:not(:last-child)": {
        "&:after": {
          width: "calc(100% - 30px)",
          content: '""',
          display: "block",
          height: "1px",
          marginLeft: "15px",
          backgroundColor: grayColor[14]
        }
      }
    }
  },
  listItemText: {
    padding: "0 !important"
  },
  itemLink:{
    color: changeColor[0],
    fontSize:'14px',
    fontFamily: 'Arial',
    padding: "17px 8px",
    margin: "0 22px",
    cursor: 'pointer',
    position:'relative',
    display: "block",
    "&:hover,&:focus": {
      color: changeColor[0],
    }
  },
  itemLinkActive:{
    color: changeColor[1],
    fontSize:'14px',
    fontFamily: 'Arial',
    padding: "17px 8px",
    margin: "0 22px",
    cursor: 'pointer',
    display: "block",
    borderBottom:`2px solid ${changeColor[1]}`,
    [theme.breakpoints.down("sm")]: {
      border:0,
    },
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition
  },
  closeButtonDrawer: {
    position: "absolute",
    right: "8px",
    top: "9px",
    zIndex: "1"
  },
  itemLinkBtn:{
    cursor: 'pointer',
    color: "#242424",
    margin: "0 22px",
    display: "block",
    padding: "17px 8px",
    position: "relative",
    fontSize: "14px",
  },
  itemLinkRow:{
    fontSize: "12px",
    color: "#87878B",
    padding: "4px 16px",
    "&:hover,&:focus": {
      color: changeColor[0],
    }
  },
  footerLinks:{
    background: "#ffffff",
  },
  footerLinksContainer:{
    ...container,
    zIndex: 1,
    padding: "32px 0",
  },
  copyright:{
    textAlign:'center',
    marginBottom:"24px"
  },
  linkItem:{
    padding:"0 12px"
  }
});

export default appStyle;
