import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
//  notistack
import { SnackbarProvider } from 'notistack';
//  core components
import { Notifier } from "features/common"
import Button from "@material-ui/core/Button";
import Avatar from '@material-ui/core/Avatar';

import ConnectWallet from 'components/ConnectWallet'
//  @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//  hooks
import { useConnectWallet, useDisconnectWallet } from '../home/redux/hooks';
//  i18n
//  web3Modal
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
//  core pages
//  style for this page
import appStyle from "./jss/appStyle.js";
import { notice } from '../../features/configure/pools'

const useStyles = makeStyles(appStyle);

export default function App({ children }) {
  const classes = useStyles();
  const { connectWallet, web3, address, networkId, connected, connectWalletPending } = useConnectWallet();
  const { disconnectWallet } = useDisconnectWallet();
  const [web3Modal, setModal] = useState(null)


  const [showNotice, setShowNotice] = useState(false);
  const outWallet = (web3, web3Modal) => {
    disconnectWallet(web3, web3Modal)
    window.location.reload()
  }

  useEffect(() => {
    const newModal = new Web3Modal({
      network: process.env.NETWORK ? process.env.NETWORK : "mainet",
      cacheProvider: true,
      providerOptions: {
        injected: {
          display: {
            name: "Injected",
            description: "Connect Wallet"
          },
        },
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: process.env.INFURA_ID
          }
        }
      }
    })
    setModal(newModal)
  }, [setModal])

  useEffect(() => {
    if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
      connectWallet(web3Modal);
    }
  }, [web3Modal, connectWallet])

  useEffect(() => {
    if (web3 && address && !connectWalletPending && networkId && Boolean(networkId !== Number(process.env.NETWORK_ID))) {
      alert('Network error')
    }
  }, [web3, address, networkId, connectWalletPending])

  return (
    <SnackbarProvider>
      <div className={classes.page}>
        <div className={classes.header}>
          <Button className={classes.title}>
            <Avatar alt="Best DeFi yield aggregator for Tokens" src={require(`../../images/BT-logo.png`)} />
            <Link to="/">
              <div className={classes.titleBrand}>BT.Finance</div>
              <div className={classes.titleTxt}>Best yield for Tokens！</div>
            </Link>
          </Button>

          <ConnectWallet
            address={address}
            connected={connected}
            connectWallet={() => connectWallet(web3Modal)}
            disconnectWallet={() => outWallet(web3, web3Modal)} />
        </div>
        <div className={classes.container}>
          {
            showNotice ? (
              <div className={classes.noticeWrap}>
                <div className={classes.notice}>{notice.title}</div>
                <div className={classes.close} onClick={() => { setShowNotice(false) }}>
                  <img className={classes.closeImg} src={require('../../images/close_icon.png')} alt="" />
                </div>
              </div>
            ) : null
          }
          <div className={classes.children}>
            {Boolean(networkId === Number(process.env.NETWORK_ID)) && children}
            <Notifier />
          </div>
        </div>
      </div>
    </SnackbarProvider>
  );
}
