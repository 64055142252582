import React, { useEffect } from 'react';



export default function HomePage() {

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  
  return (
    <>
      <div>1234</div>
    </>
  );
}