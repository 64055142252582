import React, { useEffect } from 'react';

import SectionClaim from './sections/SectionClaim';
// hooks

export default function ClaimPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  return (
    <>
      <SectionClaim />
    </>
  );
}