
import { changeColor } from "assets/jss/material-kit-pro-react.js";

const secondStyle = {
  fontFamily: 'Arial',
  fontSize: '14px',
  color: changeColor[2],
  letterSpacing: '0',
  lineHeight: '14px',
};

const farmItemStyle = theme => ({
  mainTitle: {
    fontFamily: 'Arial',
    fontSize: '32px',
    color: changeColor[2],
    letterSpacing: '0',
    lineHeight: '32px',
    fontWeight: "Bold",
    marginTop: "20px",
  },
  secondTitle: {
    ...secondStyle,
    margin: "15px 0",
    fontWeight: "400",
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  farmItem: {
    minWidth: 260,
    margin: '10px 10px 50px',
    padding: 32,
    borderRadius: 20,

    color: '#ffffff',
    textAlign: 'center'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center'
  },
  logoImage: {
    width: 60,
    height: 60,
    zIndex: 1
  },
  weightFont: {
    fontSize: 24,
    fontWeight: 500
  },
  txt:{
    fontSize: 14,
    lineHeight: '20px',
    marginTop: "8px",
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  menuButton: {
    borderRadius: 6,
    fontSize: 16,
    margin: "0 25px",
    width: "auto",
    border: "none",
    padding: "10px 30px",
    fontWeight: "normal",
    lineHeight: "21px",
    "&:hover,&:focus": {
      color: changeColor[1],
    }
  }
});

export default farmItemStyle;
