import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { shallowEqual } from 'react-redux'; // *
import {
  FARM_FETCH_BALANCE_BEGIN,
  FARM_FETCH_BALANCE_SUCCESS,
  FARM_FETCH_BALANCE_FAILURE,
} from './constants';


import { fetchBalance } from "../../web3";
import async from 'async';

export function fetchBalances(data) {
  return (dispatch,getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FARM_FETCH_BALANCE_BEGIN,
    });
    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // See the real-word example at:  https://github.com/supnate/rekit/blob/master/src/features/home/redux/fetchRedditReactjsList.js
      // args.error here is only for test coverage purpose.
      const { address, web3 } = data;
      const { farm } = getState()
      const { tokens } = farm

      const newTokens = [];
      for (let key in tokens) {
        newTokens.push({
          token: key,
          tokenAddress: tokens[key].tokenAddress,
          tokenBalance: tokens[key].tokenBalance,
        });
      }
      async.map(newTokens, (token, callback) => {
        async.parallel([
          (callbackInner) => { 
            fetchBalance({
              web3,
              address,
              tokenAddress: token.tokenAddress
            }).then(
              data => callbackInner(null, data)
            ).catch(
              error => {
                return callbackInner(error.message || error)
              }
            )
          }
        ], (error, data) => {
          token.tokenBalance = data[0] || 0
          callback(null, token)
        })
      }, (error, tokens) => {
        if(error) {
          console.log(error)
          dispatch({
            type: FARM_FETCH_BALANCE_FAILURE,
          })
          return reject(error.message || error)
        }
        const newTokens = {};
        for(let i = 0; i < tokens.length; i++) {
          newTokens[tokens[i].token] = {
            tokenAddress: tokens[i].tokenAddress,
            tokenBalance: tokens[i].tokenBalance
          }
        }
        dispatch({
          type: FARM_FETCH_BALANCE_SUCCESS,
          data: newTokens,
        })
        resolve()
      })
    });
    return promise;
  }
}


export function useFetchBalance() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { tokens, fetchBalancePending } = useSelector(
    state => ({
      tokens: state.farm.tokens,
      fetchBalancePending: state.farm.fetchBalancePending,
    })
  );

  const boundAction = useCallback(
    data => dispatch(fetchBalances(data)),
    [dispatch],
  );

  return {
    tokens,
    fetchBalances: boundAction,
    fetchBalancePending
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FARM_FETCH_BALANCE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchBalancesPending: {
          ...state.fetchBalancesPending,
          [action.index]: true
        },
      };

    case FARM_FETCH_BALANCE_SUCCESS:
      // The request is success
      
      return {
        ...state,
        tokens: action.data,
        fetchBalancesPending: {
          ...state.fetchBalancesPending,
          [action.index]: false
        },
      };

    case FARM_FETCH_BALANCE_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchBalancesPending: {
          ...state.fetchBalancesPending,
          [action.index]: false
        },
      };

    default:
      return state;
  }
}