import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  STAKE_FETCH_STAKE_BEGIN,
  STAKE_FETCH_STAKE_SUCCESS,
  STAKE_FETCH_STAKE_FAILURE,
} from './constants';

import BigNumber from "bignumber.js";
import { byDecimals } from 'features/helpers/bignumber';
import { enqueueSnackbar } from '../../common/redux/actions'
import { fetchBalance } from "../../web3";

export function fetchDepositStake(data) {
  const { index } = data;
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: STAKE_FETCH_STAKE_BEGIN,
      index
    });
    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise(async (resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // See the real-word example at:  https://github.com/supnate/rekit/blob/master/src/features/home/redux/fetchRedditReactjsList.js
      // args.error here is only for test coverage purpose.
      const { address, web3, pool } = data;
      const { stake } = getState();
      const { pools } = stake;
      const stakePool = pools[pools.findIndex(item => item.name === pool.stakeName)]
      const { earnContractAbi, earnContractAddress,tokenDecimals } = stakePool;
      const contract = new web3.eth.Contract(earnContractAbi, earnContractAddress);
      fetchBalance({
        web3,
        address,
        tokenAddress: stakePool.tokenAddress
      }).then(
        data => {
          const valueByDecimals = byDecimals(data,tokenDecimals)
          const value = new BigNumber(valueByDecimals).multipliedBy(new BigNumber(new BigNumber(10).exponentiatedBy(stakePool.valueDecimals)) ).dividedToIntegerBy( new BigNumber(1)).dividedBy( new BigNumber(new BigNumber(10).exponentiatedBy(stakePool.valueDecimals)) ).toNumber()
          const amount = new BigNumber(value).multipliedBy(new BigNumber(10).exponentiatedBy(tokenDecimals)).toString(10)
          contract.methods.stake(amount).send({ from: address }).on(
            'transactionHash', function (hash) {
              dispatch(enqueueSnackbar({
                message: hash,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: 'success'
                },
                hash
              }));
            })
            .on('receipt', function (receipt) {
              dispatch(enqueueSnackbar({
                key: new Date().getTime() + Math.random(),
                message: 'Transaction confirmation',
                options: {
                  variant: 'success',
                },
              }));
              dispatch({ type: STAKE_FETCH_STAKE_SUCCESS, index });
              resolve();
            })
            .on('error', function (error) {
              dispatch(enqueueSnackbar({
                message: error.message || error,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: 'error'
                },
              }));
              dispatch({ type: STAKE_FETCH_STAKE_FAILURE, index });
              resolve();
            })
            .catch((error) => {
              dispatch({ type: STAKE_FETCH_STAKE_FAILURE, index });
              reject(error)
            })
        }
      ).catch(
        error => {
          console.log(error.message || error);
        }
      )
    });
    return promise;
  }
}


export function useFetchDepositStake() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { fetchStakePending } = useSelector(
    state => ({
      fetchStakePending: state.stake.fetchStakePending,
    })
  );

  const boundAction = useCallback(
    (data) => dispatch(fetchDepositStake(data)),
    [dispatch],
  );

  return {
    fetchDepositStake: boundAction,
    fetchStakePending
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case STAKE_FETCH_STAKE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchStakePending: {
          ...state.fetchStakePending,
          [action.index]: true
        },
      };

    case STAKE_FETCH_STAKE_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchStakePending: {
          ...state.fetchStakePending,
          [action.index]: false
        },
      };

    case STAKE_FETCH_STAKE_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchStakePending: {
          ...state.fetchStakePending,
          [action.index]: false
        },
      };

    default:
      return state;
  }
}