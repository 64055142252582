import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  STAKE_FETCH_CURRENTLY_STAKED_BEGIN,
  STAKE_FETCH_CURRENTLY_STAKED_SUCCESS,
  STAKE_FETCH_CURRENTLY_STAKED_FAILURE,
} from './constants';

import { fetchStaked } from "../../web3";
import async from 'async';

export function fetchCurrentlyStaked(data) {
  return (dispatch) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: STAKE_FETCH_CURRENTLY_STAKED_BEGIN,
    });
    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // See the real-word example at:  https://github.com/supnate/rekit/blob/master/src/features/home/redux/fetchRedditReactjsList.js
      // args.error here is only for test coverage purpose.
      const { address, web3, currentlyStaked } = data;

      const newTokens = [];
      for (let key in currentlyStaked) {
        newTokens.push({
          token: key,
          earnContractAddress: currentlyStaked[key].earnContractAddress,
          tokenStaked: currentlyStaked[key].tokenStaked,
          earnContractAbi: currentlyStaked[key].earnContractAbi
        });
      }
      async.map(newTokens, (token, callback) => {
        async.parallel([
          (callbackInner) => { 
            fetchStaked({
              web3,
              address,
              earnContractAddress: token.earnContractAddress,
              earnContractAbi:token.earnContractAbi
            }).then(
              data => callbackInner(null, data)
            ).catch(
              error => {
                return callbackInner(error.message || error)
              }
            )
          }
        ], (error, data) => {
          token.tokenStaked = data[0] || 0
          callback(null, token)
        })
      }, (error, tokens) => {
        if(error) {
          console.log(error)
          dispatch({
            type: STAKE_FETCH_CURRENTLY_STAKED_FAILURE,
          })
          return reject(error.message || error)
        }
        const newTokens = {};
        for(let i = 0; i < tokens.length; i++) {
          newTokens[tokens[i].token] = {
            earnContractAddress: tokens[i].earnContractAddress,
            tokenStaked: tokens[i].tokenStaked,
            earnContractAbi: tokens[i].earnContractAbi
          }
        }
        dispatch({
          type: STAKE_FETCH_CURRENTLY_STAKED_SUCCESS,
          data: newTokens,
        })
        resolve()
      })
    });
    return promise;
  }
}


export function useFetchCurrentlyStaked() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { currentlyStaked, fetchCurrentlyStakedPending } = useSelector(
    state => ({
      currentlyStaked: state.stake.currentlyStaked,
      fetchCurrentlyStakedPending: state.stake.fetchCurrentlyStakedPending,
    })
  );

  const boundAction = useCallback(
    data => dispatch(fetchCurrentlyStaked(data)),
    [dispatch],
  );

  return {
    currentlyStaked,
    fetchCurrentlyStaked: boundAction,
    fetchCurrentlyStakedPending
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case STAKE_FETCH_CURRENTLY_STAKED_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchCurrentlyStakedPending: {
          ...state.fetchCurrentlyStakedPending,
          [action.index]: true
        },
      };

    case STAKE_FETCH_CURRENTLY_STAKED_SUCCESS:
      // The request is success
      return {
        ...state,
        currentlyStaked: action.data,
        fetchCurrentlyStakedPending: {
          ...state.fetchCurrentlyStakedPending,
          [action.index]: false
        },
      };

    case STAKE_FETCH_CURRENTLY_STAKED_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchCurrentlyStakedPending: {
          ...state.fetchCurrentlyStakedPending,
          [action.index]: false
        },
      };

    default:
      return state;
  }
}