

const initialState = {
  claimAddress:{},
  claimAddressPending:false,
  isClaim:true,
  isClaimPending:false,
  claimPending:false,
};

export default initialState;