import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  FARM_FETCH_POOLS_INFO_BEGIN,
  FARM_FETCH_POOLS_INFO_SUCCESS,
  FARM_FETCH_POOLS_INFO_FAILURE,
} from './constants';

import { erc20ABI } from "../../configure";
import { fetchAllowance } from "../../web3";
import async from 'async';

export function fetchPoolsInfo(data) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({ type: FARM_FETCH_POOLS_INFO_BEGIN });

    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      const { address, web3 } = data;
      const { farm } = getState()
      const { pools } = farm
      async.map(pools, (pool, callback) => {
        const erc20Contract = new web3.eth.Contract(erc20ABI, pool.tokenAddress);
        async.parallel([
          (callbackInner) => {
            fetchAllowance({
              web3,
              contractAddress: pool.earnContractAddress,
              contract: erc20Contract,
              address
            }).then(
              data => {
                return callbackInner(null, data)
              }
            ).catch(
              error => {
                return callbackInner(error, 0)
              }
            )
          },
        ], (error, data) => {
          if (error) {
            console.log(error)
          }
          pool.allowance = data[0] || 0;
          callback(null, pool);
        })
      }, (error, pools) => {
        if (error) {
          dispatch({
            type: FARM_FETCH_POOLS_INFO_FAILURE,
          })
          return reject(error.message || error)
        }
        dispatch({
          type: FARM_FETCH_POOLS_INFO_SUCCESS,
          data: pools,
        })
        resolve()
      })
    });
    return promise;
  };
}

export function useFetchPoolsInfo() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { pools, fetchPoolsInfoPending } = useSelector(
    state => ({
      pools: state.farm.pools,
      fetchPoolsInfoPending: state.farm.fetchPoolsInfoPending,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    data => dispatch(fetchPoolsInfo(data)),
    [dispatch],
  );

  return {
    fetchPoolsInfo: boundAction,
    pools,
    fetchPoolsInfoPending
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FARM_FETCH_POOLS_INFO_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchPoolsInfoPending: true
      };

    case FARM_FETCH_POOLS_INFO_SUCCESS:
      // The request is success
      return {
        ...state,
        poolsInfo: action.data,
        fetchPoolsInfoPending: false
      };

    case FARM_FETCH_POOLS_INFO_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchPoolsInfoPending: false
      };

    default:
      return state;
  }
}