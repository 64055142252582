import { stakePools } from "../../configure";

// const allowance = [0, 0, 0, 0, 0];
const balance = [0, 0, 0, 0, 0];
// const currentlyStaked = [0, 0, 0, 0, 0];
// const rewardsAvailable = [0,0,0,0,0];
const halfTime = [0, 0, 0, 0, 0];
const canWithdrawTime = [0, 0, 0, 0, 0];

const tokens = {};
const rewardsAvailable = {};
const currentlyStaked = {};
const allowance = {}

stakePools.map(({ token, earnedToken,name, tokenAddress,earnedTokenAddress, earnContractAddress, earnContractAbi }) => {
	tokens[tokenAddress] = {
		tokenAddress: tokenAddress,
		tokenBalance: 0
	}
	rewardsAvailable[tokenAddress] = {
		earnContractAddress: earnContractAddress,
		earnContractAbi: earnContractAbi,
		tokenRewards: 0
	}
	currentlyStaked[tokenAddress] = {
		earnContractAddress: earnContractAddress,
		earnContractAbi: earnContractAbi,
		tokenStaked: 0
	}
	// tokens[earnedToken] = {
	//   tokenAddress: earnedTokenAddress,
	//   tokenBalance: 0
	// }
	return '';
})


const initialState = {
	pools: stakePools,
	tokens,
	allowance,
	currentlyStaked,
	rewardsAvailable,
	halfTime,
	canWithdrawTime,
	balance,
  contractApy: {},
	fetchPoolsInfoPending: {},
	checkApprovalPending: [false, false, false, false, false],
	fetchBalancePending: {},
	fetchCurrentlyStakedPending: {},
	fetchRewardsAvailablePending: {},
	fetchHalfTimePending: [false, false, false, false, false],
	fetchCanWithdrawTimePending: [false, false, false, false, false],

	fetchApprovalPending: {},
	fetchStakePending: {},
	fetchWithdrawPending: {},
	fetchClaimPending: {},
	fetchExitPending: {},
	fetchContractApyPending:{}
};

export default initialState;