import BigNumber from "bignumber.js";

export const fetchStaked = async ({web3, address, earnContractAbi, earnContractAddress }) => {
  // console.log(`=====================================fetchBalance begin=====================================`)
  // if (!earnContractAddress) {
  //   const ethBalance = await web3.eth.getBalance(address)
  //   return ethBalance;
  // }
  const contract = new web3.eth.Contract(earnContractAbi, earnContractAddress)
  // console.log(`
  //   address:${address}\n
  //   tokenAddress:${tokenAddress}\n
  // `)
  // 0xdAC17F958D2ee523a2206206994597C13D831ec7 地址为usdt
  const rewards = await contract.methods.balanceOf(address).call({ from: address });
  // console.log(balance)
  // console.log(`=====================================fetchBalance success=====================================`)

  return new BigNumber(rewards).toNumber();
}