import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_IS_CLAIM_BEGIN,
  FETCH_IS_CLAIM_SUCCESS,
  FETCH_IS_CLAIM_FAILURE,
} from './constants';

import { MerkleDistributorAbi, BTU } from "../../configure";

export function fetchIsClaim({ address, web3, index }) {
  return dispatch => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_IS_CLAIM_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {

      const contract = new web3.eth.Contract(MerkleDistributorAbi, BTU.token);

      contract.methods.isClaimed(index).call({ from: address }).then(
        data => {
          dispatch({
            type: FETCH_IS_CLAIM_SUCCESS,
            data: data
          })
          resolve();
        },
      ).catch(
        // Use rejectHandler as the second argument so that render errors won't be caught.
        error => {
          dispatch({
            type: FETCH_IS_CLAIM_FAILURE,
          });
          reject(error.message || error);
        }
      )
    });
    return promise;
  }
}

export function useFetchIsClaim() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { isClaim, isClaimPending } = useSelector(
    state => ({
      isClaim: state.claim.isClaim,
      isClaimPending: state.claim.isClaimPending,
    })
  );

  const boundAction = useCallback(data => dispatch(fetchIsClaim(data)), [dispatch]);

  return {
    isClaim,
    fetchIsClaim: boundAction,
    isClaimPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_IS_CLAIM_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        isClaimPending: true,
      };

    case FETCH_IS_CLAIM_SUCCESS:
      // The request is success
      return {
        ...state,
        isClaim: action.data,
        isClaimPending: false,
      };

    case FETCH_IS_CLAIM_FAILURE:
      // The request is failed
      return {
        ...state,
        isClaimPending: false,
      };

    default:
      return state;
  }
}