import {
  changeColor,
} from "assets/jss/material-kit-pro-react.js";
const stakePoolStyles = theme => ({
  mainTitle: {
    width: "100%",
    fontFamily: 'Arial',
    color: changeColor[2],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "12px",
  },
  back: {
    fontSize: '14px',
    padding: "0 20px",
    background: changeColor[1],
    borderRadius: "13px",
    lineHeight: '24px',
    color: "#ffffff",
    cursor: "pointer"
  },
  title: {
    flex: 1,
    fontSize: '24px',
    textAlign: "center",
    fontWeight: 'Bold',
  },
  stakeWrap: {
    marginTop: "24px",
    minHeight: "458px",
    borderRadius: "20px",
    padding: "40px 0 28px"
  },
  stakeContainer: {
    width: "84%",
    margin: "0 auto",
  },
  logo: {
    display: 'flex',
    justifyContent: 'center'
  },
  logoImage: {
    width: 60,
    height: 60,
    zIndex: 1
  },
  name: {
    fontSize: "22px",
    fontFamily: "Arial",
    fontWeight: "bold",
    lineHeight: "26px",
    color: "#ffffff",
    textAlign: "center",
    margin: "20px 0 32px",
  },
  containerMainTitle: {
    fontSize: "20px",
    fontFamily: "Arial",
    fontWeight: "bold",
    color: "#ffffff",
    textAlign: "center",
  },
  containerSubTitle: {
    fontSize: "14px",
    fontFamily: "Arial",
    color: "#ffffff",
    textAlign: "center",
    opacity: "0.7",
  },
  stakeInput: {
    borderRadius: "12px",
    color: '#FFFFFF',
  },
  sliderWrap: {
    width: "100%",
    padding: "20px 10px 0",
  },
  depositedBalanceSliderRoot: {
    color: "rgba(255, 255, 255, 0.5)",
  },
  depositedBalanceSliderRail:{
    color: "rgba(255, 255, 255, 0.5)",
  },
  depositedBalanceSliderMarkLabel: {
    color: "#FFFFFF",
  },
  showDetailButtonCon: {
    width: "100%",
    display: 'flex',
    justifyContent: 'space-around',
    '& + &': {
      marginLeft: '5px'
    }
  },
  staleBtn: {
    border: "none",
    color: "#5993EB",
  },
  sliderDetailContainer: {
    padding: '0 12px',
  },
  showDetailOrtherButtonCon: {
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailsOrtherBtn: {
    width: "60%",
  },
  showDetailRight: {
    fontSize: '12px',
    lineHeight: '18px',
    color: "#ffffff",
    fontWeight: 'bold',
    fontFamily: 'Arial',
    marginBottom: '8px',
    margin: "20px 0 8px",
    width: "100%",
    textAlign: "right",
  },
})

export default stakePoolStyles;
