export const homeTab = [
  { value: 'Investing', title: 'Investing', link: 'https://bt.finance/vaults' },
  { value: 'Staking', title: 'Staking', link: 'https://bt.finance/stake' },
  { value: 'Docs', title: 'Docs', link: 'https://docs.bt.finance/' },
]

export const homeFooter = [
  { title: 'Twitter', link: 'https://twitter.com/btdotfinance', img: require('../../assets/img/Twitter.png') },
  { title: 'Medium', link: 'https://medium.com/@btfinance', img: require('../../assets/img/Medium.png') },
  { title: 'Telegram', link: 'https://t.me/btdotfinance', img: require('../../assets/img/Telegram.png') },
  { title: 'Discord', link: 'https://discord.com/invite/9y5nKaqjmP', img: require('../../assets/img/Discord.png') },
  { title: 'Github', link: 'https://github.com/btdotfinance', img: require('../../assets/img/Github.png') },
  { title: 'Home', link: 'https://bt.finance', img: require('../../assets/img/Home.png') },
]

export const headerTab = [
  { value: 'vault', title: 'Vault', link: "" },
  { value: 'stake', title: 'Stake', link: "" },
  // { value: 'claim', title: 'Claim', link: "" },
  { value: 'FAQ', title: 'FAQ', link: 'https://docs.bt.finance/faq' },
  { value: 'Audits', title: 'Audits', link: 'https://github.com/btdotfinance/bt-finance/tree/master/audits' },
  { value: 'Bug Bounty', title: 'Bug Bounty', link: 'https://immunefi.com/bounty/btfinance' },
  { value: 'Github', title: 'Github', link: 'https://github.com/btdotfinance' },
  { value: 'Bridge', title: 'Bridge', link: 'https://multichain.xyz/' },	
]

export const footerTab = [
  { title: 'Twitter', link: 'https://twitter.com/btdotfinance' },
  { title: 'Medium', link: 'https://medium.com/@btfinance' },
  { title: 'Discord', link: 'https://discord.com/invite/9y5nKaqjmP' },
  { title: 'Telegram', link: 'https://t.me/btdotfinance' },
  { title: 'Docs', link: 'https://docs.bt.finance' },
  { title: 'Stats', link: 'https://stats.bt.finance' },
  { title: 'Vote', link: 'https://vote.bt.finance' },
  { title: 'BT-ETH', link: 'https://info.uniswap.org/pair/0x1adac7de5c5d9894a4f6a80868ade96f8a2ed0eb' },
]

export const partnersTab = [
  { title: 'peckshield', link: 'https://www.peckshield.com', img: require('../../images/partners/0.png') },
  { title: 'coverprotocol', link: 'https://app.coverprotocol.com/app/marketplace/protocols/BTFINANCE', img: require('../../images/partners/1.png') },
  { title: 'debank', link: 'https://debank.com/projects/btfinance', img: require('../../images/partners/2.png') },
  { title: 'defillama', link: 'https://defillama.com/protocol/bt.finance', img: require('../../images/partners/3.png') },
  { title: 'coinmarketcap', link: 'https://coinmarketcap.com/currencies/bt-finance/', img: require('../../images/partners/4.jpg') },
  { title: 'coingecko', link: 'https://www.coingecko.com/en/coins/bt-finance', img: require('../../images/partners/5.jpg') },
]
