export const CLAIM_ADDRESS_BEGIN = 'CLAIM_ADDRESS_BEGIN';
export const CLAIM_ADDRESS_SUCCESS = 'CLAIM_ADDRESS_SUCCESS';
export const CLAIM_ADDRESS_FAILURE = 'CLAIM_ADDRESS_FAILURE';

export const FETCH_IS_CLAIM_BEGIN = 'FETCH_IS_CLAIM_BEGIN';
export const FETCH_IS_CLAIM_SUCCESS = 'FETCH_IS_CLAIM_SUCCESS';
export const FETCH_IS_CLAIM_FAILURE = 'FETCH_IS_CLAIM_FAILURE';


export const FETCH_CLAIM_BEGIN = 'FETCH_CLAIM_BEGIN';
export const FETCH_CLAIM_SUCCESS = 'FETCH_CLAIM_SUCCESS';
export const FETCH_CLAIM_FAILURE = 'FETCH_CLAIM_FAILURE';