import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_CLAIM_BEGIN,
  FETCH_CLAIM_SUCCESS,
  FETCH_CLAIM_FAILURE,
} from './constants';

import { claim } from "../../web3";

export function fetchClaim({ address, web3, index, amount, merkleProof }) {
  return dispatch => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_CLAIM_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      claim({
        web3, address, index, amount, merkleProof, dispatch
      }).then(
        data => {
          dispatch({
            type: FETCH_CLAIM_SUCCESS,
            data: data
          })
          resolve();
        }
      ).catch(
        error => {
          dispatch({
            type: FETCH_CLAIM_FAILURE,
            index
          })
          reject(error.message || error);
        }
      )
    });
    return promise;
  };
}

export function useFetchClaim() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const {  claimPending } = useSelector(
    state => ({
      claimPending: state.claim.claimPending,
    })
  );

  const boundAction = useCallback(data => dispatch(fetchClaim(data)), [dispatch]);

  return {
    fetchClaim: boundAction,
    claimPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_CLAIM_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        claimPending: true,
      };

    case FETCH_CLAIM_SUCCESS:
      // The request is success
      return {
        ...state,
        claimPending: false,
      };

    case FETCH_CLAIM_FAILURE:
      // The request is failed
      return {
        ...state,
        claimPending: false,
      };

    default:
      return state;
  }
}