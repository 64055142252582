// import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  STAKE_FETCH_POOLS_INFO_BEGIN,
  STAKE_FETCH_POOLS_INFO_SUCCESS,
  STAKE_FETCH_POOLS_INFO_FAILURE,
} from './constants';

import { erc20ABI } from "../../configure";
import { fetchAllowance } from "../../web3";
import async from 'async';

export function fetchPoolsInfo(data) {
  return (dispatch) => {
    // optionally you can have getState as the second argument
    dispatch({ type: STAKE_FETCH_POOLS_INFO_BEGIN });

    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // args.error here is only for test coverage purpose.
      const { address, web3, pools } = data;
      async.map(pools, (pool, callback) => {
        const erc20Contract = pool.tokenAddress ? new web3.eth.Contract(erc20ABI, pool.tokenAddress) : null;
        async.parallel([
          (callbackInner) => {
            fetchAllowance({
              web3,
              contractAddress: pool.earnContractAddress,
              contract: erc20Contract,
              address
            }).then(
              data => {
                return callbackInner(null, data)
              }
            ).catch(
              error => {
                return callbackInner(error, 0)
              }
            )
          }
        ], (error, data) => {
            if (error) {
              console.log(error)
            }
            pool.allowance = data[0] || 0;
            callback(null, pool);
        })
      }, (error, pools) => {
        if(error) {
          dispatch({
            type: STAKE_FETCH_POOLS_INFO_FAILURE,
          })
          return reject(error.message || error)
        }
        dispatch({
          type: STAKE_FETCH_POOLS_INFO_SUCCESS,
          data: pools,
        })
        resolve()
      })
    });
    return promise;
  };
}

export function useFetchPoolsInfo() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { pools, fetchPoolsInfoPending } = useSelector(
    state => ({
      pools: state.stake.pools,
      fetchPoolsInfoPending: state.stake.fetchPoolsInfoPending,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    data => dispatch(fetchPoolsInfo(data)),
    [dispatch],
  );

  return {
    pools,
    fetchPoolsInfo: boundAction,
    fetchPoolsInfoPending
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case STAKE_FETCH_POOLS_INFO_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchPoolsInfoPending: {
          ...state.fetchPoolsInfoPending,
          [action.index]: true
        },
      };

    case STAKE_FETCH_POOLS_INFO_SUCCESS:
      // The request is success
      return {
        ...state,
        poolsInfo: action.data,
        fetchPoolsInfoPending: {
          ...state.fetchPoolsInfoPending,
          [action.index]: false
        },
      };

    case STAKE_FETCH_POOLS_INFO_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchPoolsInfoPending: {
          ...state.fetchPoolsInfoPending,
          [action.index]: false
        },
      };

    default:
      return state;
  }
}