import React,{useEffect} from 'react';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import farmItemStyle from "../jss/sections/farmItemStyle";
import Grid from '@material-ui/core/Grid';
// core components
import Button from "components/CustomButtons/Button.js";
import { useFetchPoolsInfo } from '../redux/hooks';
import { Avatar } from "@material-ui/core";

import { useFetchContractApy } from '../../stake/redux/hooks';

const useStyles = makeStyles(farmItemStyle);


export default () => {
  
  const classes = useStyles();
  const { pools } = useFetchPoolsInfo();
  
  const { contractApy, fetchContractApy } = useFetchContractApy();

  useEffect(() => {
    fetchContractApy();
    const id = setInterval(() => {
      fetchContractApy();
    }, 10000);
    return () => clearInterval(id);
  }, [fetchContractApy]);


  const offsetImageStyle = { marginLeft: "-25%", zIndex: 0, background: '#ffffff' }
  return (
    <Grid container style={{ paddingTop: '4px' }}>
      <Grid item xs={12}>
        <div className={classes.mainTitle}>Stake</div>
        <div className={classes.secondTitle}>Earn BT Token By Staking BT、LPs and bTokens.</div>
      </Grid>
      <Grid container item xs={12} justify={"center"}>
        {pools.map((pool, index) => {
          const { token, name, earnedToken, earnedTokenAddress, color, tokenDescription, id } = pool;
          const isLP = id.toLowerCase().indexOf('lp') > -1;
          const lpTokens = isLP ? token.split('-') : [];
          
          let depositedApy = `${contractApy[pool.token] || 0}%`;

          return (
            <Grid item md={6} xs={12} key={index}>
              <div style={{ background: `rgba(${color},1)` }} className={classNames({
                [classes.flexColumnCenter]: true,
                [classes.farmItem]: true
              })} key={index}>
                {isLP && lpTokens.length === 2 ? (
                  <div className={classes.logo}>
                    {lpTokens.map((item, index) => {
                      return (
                        <Avatar key={index}
                          src={require(`../../../images/${item}-logo.png`)} className={classes.logoImage}
                          style={index > 0 ? offsetImageStyle : {}}
                        />
                      )
                    })}
                  </div>
                ) : <Avatar src={require(`../../../images/${token}-logo.png`)} className={classes.logoImage} />}
                <div className={classes.weightFont} style={{ marginTop: 12 }}>{name}</div>

                <div className={classes.txt}>
                Deposit {tokenDescription}
                </div>
                <div className={classes.txt}>Earn {earnedToken}</div>

                <div className={classes.weightFont} style={{ margin: 28 }}>APY {depositedApy}</div>

                <div className={classes.menu} style={isLP ? {} : { justifyContent: 'center' }}>
                  {isLP ? (
                    <>
                      <Button className={classes.menuButton} href={`/pool?index=${index + 1}`} style={{ color: `#FEA42E` }}>
                      Stake
                      </Button>
                      <Button
                        className={classes.menuButton}
                        href={`https://app.uniswap.org/#/add/ETH/${earnedTokenAddress}`}
                        target={"_blank"}
                        style={{ color: `#FEA42E` }}
                      >
                        Get LP Token
                      </Button>
                    </>
                  ) : <Button
                    className={classes.menuButton} href={`/pool?index=${index + 1}`} style={{ color: `#5993EB` }}>Stake</Button>}
                </div>
              </div>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}