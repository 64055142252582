/* eslint-disable */
import React, { useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import Footer from "components/Footer/Footer.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Popover from '@material-ui/core/Popover';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/components/footerLinksStyle.js";
import classNames from "classnames";
import {
    grayColor,
    roseColor,
    primaryColor,
    secondaryColor,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    blackColor,
    whiteColor,
    twitterColor,
    facebookColor,
    googleColor,
    linkedinColor,
    pinterestColor,
    youtubeColor,
    tumblrColor,
    behanceColor,
    dribbbleColor,
    redditColor,
    instagramColor,
    hexToRgb
} from "assets/jss/material-kit-pro-react.js";
const useStyles = makeStyles(styles);


import { footerTab } from "../../features/configure";
export default function FooterLinks(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const color = props.color;
    const badgeClasses = classNames({
        [classes.container]: true,
        [classes.fixed]: props.fixed,
    });

    //留着控制底部联系方式的颜色
    const iconGroundStyle = {
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: "1.25rem",
        borderRadius: "1.25rem",
        color: 'white',
        backgroundColor: primaryColor[0],
    }

    const iconColorStyle = {
        fontSize: "24px",
        color: "#fff"
    }

    return (
        <div className={badgeClasses} style={{ padding: "32px 0 0" }}>
            
            <GridContainer className={classes.linkList} justify='center' align='center' >
                {
                    footerTab.map((item) => {
                        return (
                            <GridItem className={classes.linkItem} md={1} xs={6} key={item.title} >
                                <a className={classes.extraContent} href={item.link} target="_blank">{item.title}</a>
                            </GridItem >
                        )
                    })
                }
            </GridContainer>

            <div className={classes.copyright}>©2020 BT.Finance All Rights Reserved.</div>
        </div>
    )
}

FooterLinks.defaultProps = {
    color: "transparent",
    fixed: false
};

FooterLinks.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    fixed: PropTypes.bool,
};
