import React, { useEffect } from 'react';

import SectionHead from './sections/SectionHead';
import SectionPools from './sections/SectionPools';
import SectionFooter from './sections/SectionFooter';

// hooks

export default function VaultPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  return (
    <>
      <SectionHead fromPage='home' />
      <SectionPools fromPage='home' />
      <SectionFooter fromPage='home' />
    </>
  );
}