import { changeColor } from "assets/jss/material-kit-pro-react.js";

const sectionHeadStyle = theme => ({
  mainTitle: {
    fontFamily: 'Arial',
    fontSize: '32px',
    color: changeColor[2],
    letterSpacing: '0',
    lineHeight: '36px',
    fontWeight: "Bold",
    margin: '20px 0',
    display: "flex",
    "& span":{
      marginLeft:"4px",
      fontSize: '20px',
    }
  },
  statistics: {
    padding: "25px 20px",
    background: "linear-gradient(134deg, #40BAF8 0%, #6370F9 100%)",
    borderRadius: "10px",
    color: "#ffffff",
    fontSize: "16px",
    height: "170px",
  },
  statistics2: {
    padding: "25px 20px",
    background: "linear-gradient(133deg, #BD7DFC 0%, #8170F2 100%)",
    borderRadius: "10px",
    color: "#ffffff",
    fontSize: "16px",
    height: "170px",
  },
  statisticsItem: {
    padding: "10px 0",
    lineHeight: "18px",
    fontWeight: "Bold",
  },
  itemLabel: {
    fontSize: "14px",
    letterSpacing: "30px",
    opacity: 0.7,
    marginRight: "10px",
    fontWeight: "normal",
  },
  balanceWrap: {
    padding: "15px 20px",
    background: "linear-gradient(133deg, #8A8EFD 0%, #4A4FE2 100%)",
    borderRadius: "10px",
    color: "#ffffff",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "18px",
    height: "170px",
  },
  price: {},
  balance: {
    margin: "14px 0"
  },
  balanceBtnWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  balanceBtn: {
    fontFamily: 'Arial',
    fontWeight: "Bold",
    width: '140px',
    lineHeight: '34px',
    color: changeColor[1],
    textAlign: "center",
    background: '#FFFFFF',
    borderRadius: '19px',
    cursor: 'pointer',
    "&:hover":{
      color: changeColor[1],
    }
  },
  balanceLabel: {
    fontSize: "14px",
    letterSpacing: "30px",
    opacity: 0.7,
  },
  balanceTxt: {
    fontWeight: "Bold",
    marginTop: "4px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textCenter: {
    textAlign: "center",
  },
  updown: {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: '16px',
    marginLeft: "6px",
  },
  priceIcon: {
    width: "18px",
  },
  priceIconDown: {
    width: "18px",
    transform: "rotate(180deg)",
  },
  chainWrap: {
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: "24px 0"
  },
  chainMain: {
    width: "130px",
    height: "40px",
    lineHeight: "40px",
    background: "#4A4FE2",
    borderRadius: "25px",
    fontSize: "20px",
    color: "#ffffff",
    textAlign: 'center',
    "&:hover,&:focus": {
      color: "#ffffff",
    }
  },
  chainMinor: {
    width: "130px",
    height: "40px",
    lineHeight: "40px",
    background: "#DFE0FF",
    borderRadius: "25px",
    fontSize: "20px",
    color: "#4A4FE2",
    textAlign: 'center',
    marginLeft: "40px",
    "&:hover,&:focus": {
      color: "#4A4FE2",
    }
  }
});

export default sectionHeadStyle;
