import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { shallowEqual } from 'react-redux'; // *
import {
  FARM_STAKE_FETCH_BALANCE_BEGIN,
  FARM_STAKE_FETCH_BALANCE_SUCCESS,
  FARM_STAKE_FETCH_BALANCE_FAILURE,
} from './constants';


import { fetchStakeBalance } from "../../web3";
import async from 'async';

export function fetchStakeBalances(data) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FARM_STAKE_FETCH_BALANCE_BEGIN,
    });
    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      const { address, web3 } = data;
      const { farm } = getState()
      const { tokensStake } = farm
      const newTokens = [];
      const lpTokens = [];
      for (let key in tokensStake) {
        if (tokensStake[key].id !== 'lp') {
          newTokens.push({
            token: key,
            earnContractAddress: tokensStake[key].earnContractAddress,
            balance: tokensStake[key].balance,
            id: tokensStake[key].id
          });
        }else{
          lpTokens.push({
            token: key,
            earnContractAddress: tokensStake[key].earnContractAddress,
            balance: tokensStake[key].balance,
            id: tokensStake[key].id
          });
        }

        // if (tokensStake[key].id !== 'lp') {
        //   const btToken = tokensStake[key]
        //   fetchStakeBalance({
        //     web3,
        //     address,
        //     tokenAddress: btToken.earnContractAddress
        //   }).then((data)=>{
        //     console.log(data);
        //     btToken.balance = data
        //     dispatch({
        //       type: FARM_STAKE_FETCH_BALANCE_SUCCESS,
        //       data: newTokens,
        //     })
        //     resolve()
        //   }).catch((error) => {
        //     btToken.balance = 0
        //     dispatch({
        //       type: FARM_STAKE_FETCH_BALANCE_FAILURE,
        //     })
        //     return reject(error.message || error)
        //   })
        // }
      }

      async.map(newTokens, (token, callback) => {
        async.parallel([
          (callbackInner) => {
            fetchStakeBalance({
              web3,
              address,
              tokenAddress: token.earnContractAddress
            }).then(
              data => callbackInner(null, data)
            ).catch(
              error => {
                return callbackInner(error.message || error)
              }
            )
          }
        ], (error, data) => {
          token.balance = data[0] || 0
          callback(null, token)
        })
      }, (error, tokens) => {
        if (error) {
          console.log(error)
          dispatch({
            type: FARM_STAKE_FETCH_BALANCE_FAILURE,
          })
          return reject(error.message || error)
        }
        const newTokens = {};
        for (let i = 0; i < tokens.length; i++) {
          newTokens[tokens[i].token] = {
            earnContractAddress: tokens[i].earnContractAddress,
            balance: tokens[i].balance,
            id: tokens[i].id
          }
        }
        const lp = {}
        for (let i = 0; i < lpTokens.length; i++) {
          lp[lpTokens[i].token] = {
            earnContractAddress: lpTokens[i].earnContractAddress,
            balance: lpTokens[i].balance,
            id: lpTokens[i].id
          }
        }
        
        dispatch({
          type: FARM_STAKE_FETCH_BALANCE_SUCCESS,
          data: {...newTokens,...lp},
        })
        resolve()
      })
    });
    return promise;
  }
}


export function useFetchStakeBalances() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { tokensStake, fetchStakeBalancePending } = useSelector(
    state => ({
      tokensStake: state.farm.tokensStake,
      fetchStakeBalancePending: state.farm.fetchStakeBalancePending,
    })
  );

  const boundAction = useCallback(
    data => dispatch(fetchStakeBalances(data)),
    [dispatch],
  );

  return {
    tokensStake,
    fetchStakeBalances: boundAction,
    fetchStakeBalancePending
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FARM_STAKE_FETCH_BALANCE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchStakeBalancePending: {
          ...state.fetchStakeBalancePending,
          [action.index]: true
        },
      };

    case FARM_STAKE_FETCH_BALANCE_SUCCESS:
      // The request is success

      return {
        ...state,
        tokensStake: action.data,
        fetchStakeBalancePending: {
          ...state.fetchStakeBalancePending,
          [action.index]: false
        },
      };

    case FARM_STAKE_FETCH_BALANCE_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchStakeBalancePending: {
          ...state.fetchStakeBalancePending,
          [action.index]: false
        },
      };

    default:
      return state;
  }
}