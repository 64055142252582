import { container, defaultFont } from "assets/jss/material-kit-pro-react.js";

const appStyle = theme => ({
  page: {
    backgroundColor: "#342D55",
    minHeight: "100vh"
  },
  container: {
    ...container,
    zIndex: 1,
  },
  children: {
    minHeight: '77vh',
  },
  noticeWrap: {
    background: "#FF65A8",
    fontSize: "16px",
    fontFamily: "Arial",
    color: "#fff",
    padding: "24px 30px",
    lineHeight: '22px',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "10px",
  },
  notice: {
  },
  close: {
    marginRight: "0",
  },
  closeImg: {
    width: "16px",
    cursor: "pointer",
  },
  header: {
    ...container,
    minHeight: "90px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
    color: '#ffffff',
  },
  title: {
    letterSpacing: "unset",
    "&,& a": {
      ...defaultFont,
      minWidth: "unset",
      lineHeight: "28px",
      padding: "0 4px",
      fontSize: "24px",
      fontWeight: "700",
      borderRadius: "3px",
      textTransform: "none",
      whiteSpace: "nowrap",
      color: "inherit",
      "&:hover,&:focus": {
        color: "inherit",
        background: "transparent"
      }
    },
    "& a": {
      marginLeft: "8px",
    }
  },
  titleBrand: {
    textAlign: "left",
  },
  titleTxt: {
    textAlign: "left",
    fontSize: "14px",
    fontFamily: "Arial",
    fontWeight: "400",
    lineHeight: "16px",
    color: '#ffffff',
  },
  logoImg: {
    width: "40px"
  }
});

export default appStyle;
