import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FARM_FETCH_REWARDS_AVAILABLE_BEGIN,
  FARM_FETCH_REWARDS_AVAILABLE_SUCCESS,
  FARM_FETCH_REWARDS_AVAILABLE_FAILURE,
} from './constants';

import { fetchRewards } from "../../web3";
import async from 'async';

export function fetchRewardsAvailable(data) {
  return (dispatch,getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: FARM_FETCH_REWARDS_AVAILABLE_BEGIN,
    });
    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // See the real-word example at:  https://github.com/supnate/rekit/blob/master/src/features/home/redux/fetchRedditReactjsList.js
      // args.error here is only for test coverage purpose.
      const { address, web3 } = data;
      const { farm } = getState()
      const { rewardsAvailable } = farm

      const newTokens = [];
      for (let key in rewardsAvailable) {
        newTokens.push({
          token: key,
          earnContractAddress: rewardsAvailable[key].earnContractAddress,
          tokenRewards: rewardsAvailable[key].tokenRewards,
          earnContractAbi: rewardsAvailable[key].earnContractAbi
        });
      }
      async.map(newTokens, (token, callback) => {
        async.parallel([
          (callbackInner) => { 
            fetchRewards({
              web3,
              address,
              earnContractAddress: token.earnContractAddress,
              earnContractAbi:token.earnContractAbi
            }).then(
              data => callbackInner(null, data)
            ).catch(
              error => {
                return callbackInner(error.message || error)
              }
            )
          }
        ], (error, data) => {
          token.tokenRewards = data[0] || 0
          callback(null, token)
        })
      }, (error, tokens) => {
        if(error) {
          console.log(error)
          dispatch({
            type: FARM_FETCH_REWARDS_AVAILABLE_FAILURE,
          })
          return reject(error.message || error)
        }
        const newTokens = {};
        for(let i = 0; i < tokens.length; i++) {
          newTokens[tokens[i].token] = {
            earnContractAddress: tokens[i].earnContractAddress,
            tokenRewards: tokens[i].tokenRewards,
            earnContractAbi: tokens[i].earnContractAbi
          }
        }
        dispatch({
          type: FARM_FETCH_REWARDS_AVAILABLE_SUCCESS,
          data: newTokens,
        })
        resolve()
      })
    });
    return promise;
  }
}



export function useFetchRewardsAvailable() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { rewardsAvailable, fetchRewardsAvailablePending } = useSelector(
    state => ({
      rewardsAvailable: state.farm.rewardsAvailable,
      fetchRewardsAvailablePending: state.farm.fetchRewardsAvailablePending,
    })
  );

  const boundAction = useCallback(
    data => dispatch(fetchRewardsAvailable(data)),
    [dispatch],
  );

  return {
    rewardsAvailable,
    fetchRewardsAvailable: boundAction,
    fetchRewardsAvailablePending
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FARM_FETCH_REWARDS_AVAILABLE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchRewardsAvailablePending: {
          ...state.fetchRewardsAvailablePending,
          [action.index]: true
        },
      };

    case FARM_FETCH_REWARDS_AVAILABLE_SUCCESS:
      // The request is success

      return {
        ...state,
        rewardsAvailable:action.data,
        fetchRewardsAvailablePending: {
          ...state.fetchRewardsAvailablePending,
          [action.index]: false
        },
      };

    case FARM_FETCH_REWARDS_AVAILABLE_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchRewardsAvailablePending: {
          ...state.fetchRewardsAvailablePending,
          [action.index]: false
        },
      };

    default:
      return state;
  }
}