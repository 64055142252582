import React, { useEffect, useState } from 'react';
//  notistack
import { SnackbarProvider } from 'notistack';
//  core components
import { Notifier } from "features/common"
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import FooterLinks from 'components/Footer/FooterLinks.js'
//  @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//  hooks
import { useConnectWallet, useDisconnectWallet } from './home/redux/hooks';
//  i18n
//  web3Modal
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
//  core pages
//  style for this page
import appStyle from "./home/jss/appStyle.js";
import { notice } from './configure/pools'

const useStyles = makeStyles(appStyle);

export default function App({ children }) {
  const classes = useStyles();
  const { connectWallet, web3, address, networkId, connected, connectWalletPending } = useConnectWallet();
  const { disconnectWallet } = useDisconnectWallet();
  const [web3Modal, setModal] = useState(null)


  const [showNotice, setShowNotice] = useState(false);
  const outWallet = (web3, web3Modal) => {
    disconnectWallet(web3, web3Modal)
    window.location.reload()
  }

  useEffect(() => {
    const newModal = new Web3Modal({
      network: process.env.NETWORK ? process.env.NETWORK : "mainet",
      cacheProvider: true,
      providerOptions: {
        injected: {
          display: {
            name: "Injected",
            description: "Connect Wallet"
          },
        },
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: process.env.INFURA_ID
          }
        }
      }
    })
    setModal(newModal)
  }, [setModal])

  useEffect(() => {
    if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
      connectWallet(web3Modal);
    }
  }, [web3Modal, connectWallet])

  useEffect(() => {
    if (web3 && address && !connectWalletPending && networkId && Boolean(networkId !== Number(process.env.NETWORK_ID))) {
      alert('Network error')
    }
    // if (Boolean(networkId === Number(process.env.NETWORK_ID)) &&web3Modal  && (web3Modal.cachedProvider || window.ethereum)) {
    //   changeConnectWallet(web3Modal)
    // }
  }, [web3, address, networkId, connectWalletPending])

  return (
    <SnackbarProvider>
      <div className={classes.page}>
        <Header
          brand="BT.Finance"
          links={
            <HeaderLinks
              dropdownHoverColor="dark"
              address={address}
              connected={connected}
              connectWallet={() => connectWallet(web3Modal)}
              disconnectWallet={() => outWallet(web3, web3Modal)}
            />
          }
        // color="dark"
        />
        <div className={classes.container}>
          {
            showNotice ? (
              <div className={classes.noticeWrap}>
                <div className={classes.notice}>{notice.title}</div>
                <div className={classes.close} onClick={() => { setShowNotice(false) }}>
                  <img className={classes.closeImg} src={require('../images/close_icon.png')} alt="" />
                </div>
              </div>
            ) : null
          }
          <div className={classes.children}>
            {children}
            <Notifier />
          </div>
          <FooterLinks />
        </div>
      </div>
    </SnackbarProvider>
  );
}
